.block-section {
  @extend .clearfix;
  position: relative;
  margin: 0 $spacing;

  @include max-width-md {
    margin-bottom: 0;
  }
}

.section-title {
  @include font-size($font-size-md);
  color: $color-grey-one;

  @include max-width-sm {
    text-align: center;
  }
}

.more-link-wrapper {
  margin: $spacing 0;
  text-align: center;
}

.more-link {
  .icon {
    margin-left: 0.5em;
    font-size: 1.3em;
  }
}

.content-narrow {
  width: 60%;
  margin: 0 auto;

  @include max-width-md {
    width: 80%;
  }
}

.block-section {
  .page-home & {
    background: $background-color-dark;
  }
}
