@charset "UTF-8";
.interactive, select, .input-field .icon {
  cursor: pointer;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}

body {
  margin: 0;
}

textarea:focus,
input:focus,
select:focus {
  outline: 0 none;
}

a {
  background: transparent;
}
a:focus {
  outline: thin dotted;
}
a:hover, a:active {
  outline: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

h3 {
  font-size: 1.17em;
  margin: 1em 0;
}

h4 {
  font-size: 1em;
  margin: 1.33em 0;
}

h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}

h6 {
  font-size: 0.75em;
  margin: 2.33em 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: "“" "”" "‘" "’";
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

html,
body {
  height: 100%;
  background-color: #fff;
}

div,
header,
footer {
  box-sizing: border-box;
}

p {
  margin-bottom: 20px;
  margin-top: 0;
}

img {
  max-width: 100%;
}

a, a:active, a:hover {
  color: #1b1b1b;
  text-decoration: none;
}

a:active, a:hover {
  color: #1b1b1b;
}

.clearfix:after, .block-section:after, .columns:after, .content:after, .site-boundary:after {
  clear: both;
}
.clearfix:before, .block-section:before, .columns:before, .content:before, .site-boundary:before, .clearfix:after, .block-section:after, .columns:after, .content:after, .site-boundary:after {
  display: table;
  content: " ";
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hidden {
  display: none !important;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.pseudo-hidden {
  overflow: hidden !important;
  visibility: hidden !important;
  padding: 0 !important;
  height: 0 !important;
  width: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
}

.pseudo-hidden-height {
  overflow: hidden !important;
  padding: 0 !important;
  height: 0 !important;
}

.reset-list,
.reset-list ol,
.reset-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.reset-list li,
.reset-list ol li,
.reset-list ul li {
  margin: 0;
  padding: 0;
}

.image-responsive > .desktop {
  display: block;
}
.image-responsive > .mobile {
  display: block;
}
.image-responsive > .desktop + .mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .image-responsive.with-mobile .mobile {
    display: block;
  }
  .image-responsive.with-mobile .desktop {
    display: none;
  }
}

.background-full {
  background-size: cover;
  background-position: center;
  background-color: transparent;
  background-repeat: no-repeat;
}

.background-opacity-transition {
  transition: opacity 0.3s ease;
}

.util-no-border {
  border: none !important;
}

@media screen and (max-width: 992px), (orientation: portrait) {
  .mobile-flex-column {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 992px), (orientation: portrait) {
  .mobile-flex-order-1 {
    order: 1;
  }
}

@media screen and (max-width: 992px), (orientation: portrait) {
  .mobile-flex-order-2 {
    order: 2;
  }
}

@media screen and (min-width: 992px) and (orientation: landscape) {
  .careers-mobile-only {
    display: none !important;
  }
}

@media screen and (max-width: 992px), (orientation: portrait) {
  .careers-desktop-only {
    display: none !important;
  }
}

@media screen and (min-width: 992px) and (max-height: 815px) and (orientation: landscape) {
  .careers-display-on-short-height {
    display: block !important;
    display: initial !important;
  }
}

.site-wrapper {
  position: relative;
  min-height: 100%;
  padding-bottom: 225px;
}
.site-wrapper.no-footer {
  padding-bottom: 0;
}
@media screen and (max-width: 992px) {
  .site-wrapper {
    padding-bottom: 0;
  }
}
.page-home .site-wrapper {
  height: 100%;
}

.main {
  position: relative;
  clear: both;
  z-index: 1;
  margin-bottom: 40px;
}
@media screen and (max-width: 992px) {
  .main {
    margin-bottom: 20px;
  }
}
.main.main-home {
  display: block;
  margin: 0;
}

.site-boundary {
  width: 100%;
  max-width: 992px;
  margin: 0 auto;
}
.site-boundary.wide {
  max-width: 100%;
}
@media screen and (max-width: 992px) {
  .site-boundary {
    max-width: 100%;
  }
  .site-boundary:not(.wide) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 768px) {
  .site-boundary.mobile-wide {
    padding-left: 0;
    padding-right: 0;
  }
}

.columns {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.columns:after {
  content: " ";
  display: block;
  clear: both;
}
.columns .col {
  margin-bottom: 20px;
}
.columns.columns-alternate .col, .columns.columns-2 .col {
  width: 49.1525423729%;
  float: left;
  margin-right: 1.6949152542%;
}
.columns.columns-alternate .col:nth-child(even), .columns.columns-2 .col:nth-child(even) {
  margin-right: 0;
}
@media screen and (max-width: 768px) {
  .columns.columns-alternate .col, .columns.columns-2 .col {
    clear: both;
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
  .columns.columns-alternate .col:nth-child(even), .columns.columns-2 .col:nth-child(even) {
    clear: both;
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
}
.columns.columns-3 .col {
  width: 32.2033898305%;
  float: left;
  margin-right: 1.6949152542%;
}
.columns.columns-3 .col:nth-child(3n) {
  margin-right: 0;
}
.columns.columns-3 .col:nth-child(3n+1) {
  clear: both;
}
@media screen and (max-width: 992px) {
  .columns.columns-3 .col {
    width: 49.1525423729%;
    float: left;
    margin-right: 1.6949152542%;
  }
  .columns.columns-3 .col:nth-child(3n) {
    width: 49.1525423729%;
    float: left;
    margin-right: 1.6949152542%;
  }
  .columns.columns-3 .col:nth-child(3n+1) {
    width: 49.1525423729%;
    float: left;
    margin-right: 1.6949152542%;
    clear: none;
  }
  .columns.columns-3 .col:nth-child(even) {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px) {
  .columns.columns-3 .col {
    clear: both;
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
  .columns.columns-3 .col:nth-child(3n) {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
  .columns.columns-3 .col:nth-child(3n+1) {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
  .columns.columns-3 .col:nth-child(even) {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
}

.pseudo-grid {
  display: table;
}
.pseudo-grid .grid-item {
  display: table-cell;
}
.pseudo-grid .grid-row {
  display: table-row;
}

input {
  box-sizing: border-box;
  height: 40px;
  padding: 0 10px;
  border: 1px solid #333;
}

.input-field {
  position: relative;
  display: inline-block;
  z-index: 1;
}
.input-field .icon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  height: 40px;
  line-height: 40px;
  margin: 0;
  padding: 0 10px;
  vertical-align: middle;
  color: #333;
}

.select-field {
  border: 1px solid #333;
  width: 100%;
  overflow: hidden;
}
.select-field .icon {
  font-size: 26px;
}

select {
  height: 40px;
  width: 100%;
  padding: 5px 8px;
  padding-right: 40px;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
}

.search-field {
  position: relative;
  width: 100%;
}
.search-field .icon {
  font-size: 26px;
}
.search-field .search-input {
  width: 100%;
  padding-right: 40px;
}

.transition {
  -webkit-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
  -webkit-transition-delay: 0;
  transition-delay: 0;
}
.para-panel.active .transition {
  opacity: 1;
}
.transition.transition-fadein-1, .transition.transition-fadein {
  opacity: 0;
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.transition.transition-fadein-2 {
  opacity: 0;
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}
.transition.transition-fadein-3 {
  opacity: 0;
  -webkit-transition-delay: 1.8s;
  transition-delay: 1.8s;
}
.transition.transition-fadein-4 {
  opacity: 0;
  -webkit-transition-delay: 2.4s;
  transition-delay: 2.4s;
}
.transition.transition-fadein-5 {
  opacity: 0;
  -webkit-transition-delay: 3s;
  transition-delay: 3s;
}
.transition.transition-fadein-6 {
  opacity: 0;
  -webkit-transition-delay: 3.6s;
  transition-delay: 3.6s;
}
.transition.transition-fadein-7 {
  opacity: 0;
  -webkit-transition-delay: 4.2s;
  transition-delay: 4.2s;
}

.slide-in-content {
  -webkit-transform: translate(0, 30px);
  -ms-transform: translate(0, 30px);
  -o-transform: translate(0, 30px);
  transform: translate(0, 30px);
}

/*==================================
=            DEFINE FONTS             =
==================================*/
/*==================================
=            Mixin Helpers             =
==================================*/
/*==================================
=            Load Fonts            =
  based on font style / weight
==================================*/
@font-face {
  font-family: "Lyon Text Web";
  src: url("./../fonts/LyonText-Regular-Web.eot");
  src: url("./../fonts/LyonText-Regular-Web.eot?#iefix") format("embedded-opentype"), url("./../fonts/LyonText-Regular-Web.woff") format("woff"), url("./../fonts/LyonText-Regular-Web.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lyon Text Web";
  src: url("./../fonts/LyonText-RegularItalic-Web.eot");
  src: url("./../fonts/LyonText-RegularItalic-Web.eot?#iefix") format("embedded-opentype"), url("./../fonts/LyonText-RegularItalic-Web.woff") format("woff"), url("./../fonts/LyonText-RegularItalic-Web.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Lyon Text Web";
  src: url("./../fonts/LyonText-Bold-Web.eot");
  src: url("./../fonts/LyonText-Bold-Web.eot?#iefix") format("embedded-opentype"), url("./../fonts/LyonText-Bold-Web.woff") format("woff"), url("./../fonts/LyonText-Bold-Web.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Lyon Text Web";
  src: url("./../fonts/LyonText-BoldItalic-Web.eot");
  src: url("./../fonts/LyonText-BoldItalic-Web.eot?#iefix") format("embedded-opentype"), url("./../fonts/LyonText-BoldItalic-Web.woff") format("woff"), url("./../fonts/LyonText-BoldItalic-Web.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Lyon Display Web";
  src: url("./../fonts/LyonDisplay-Medium-Web.eot");
  src: url("./../fonts/LyonDisplay-Medium-Web.eot?#iefix") format("embedded-opentype"), url("./../fonts/LyonDisplay-Medium-Web.woff") format("woff"), url("./../fonts/LyonDisplay-Medium-Web.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
/*==================================
=            Style Class Helpers   =
  use as @extend .font-family-primary;
==================================*/
.font-family-primary, .panel-bio-name-content {
  font-family: UniversLTStd, sans-serif;
}

.font-family-secondary, .panel-featured-title em, .panel-featured-title > h1, .questionnaire-step .question-title, .questionnaire-results .questionnaire-results-item, .pop-content-rich-text, .panel-content-list .description {
  font-family: Lyon Text Web, serif;
}

.font-family-display, .panel-content-list.large-description .description, .panel-grid .grid-item-value {
  font-family: Lyon Display Web, serif;
}

html {
  font-size: 16px;
}

a {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0 0 1em;
}

h1,
.h1 {
  font-size: 2.25rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.625rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5,
h6,
.h6 {
  font-size: 1.125rem;
  margin-bottom: 0.5em;
}

.button {
  display: inline-block;
  font-size: 0.75rem;
  padding: 0.8rem;
  border: 0;
  outline: 0;
  line-height: 1;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  user-select: none;
  background-color: #1b1b1b;
  color: #fff;
}
.button:active, .button:focus, .button:hover {
  color: #e6e6e6;
  background-color: #1b1b1b;
}

.button-tall {
  padding: 1.2rem;
}

.button-wide {
  display: block;
  max-width: 100%;
}

.buttons {
  margin: 10px 0;
}

.button-reset {
  font-size: 1.5rem;
  border: 1px solid #e82011;
  text-transform: none;
}

.page-title {
  background-color: #333;
  color: #fff;
  text-align: center;
}
.page-title h1 {
  font-size: 1.125rem;
  margin: 1em 0;
  font-weight: 200;
}

.page-description {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.icon {
  margin: 0 0.5em 0 0;
}
.icon.search, .icon.menu, .icon.close {
  margin: 0 auto;
  width: 1em;
  height: 1em;
  position: relative;
  display: inline-block;
  overflow: hidden;
}
.icon.menu::before {
  content: "";
  position: absolute;
  top: 0.25em;
  left: 0;
  width: 1em;
  height: 0.125em;
  border-top: 0.375em double;
  border-bottom: 0.125em solid;
  border-color: inherit;
}
.icon.close {
  width: 25px;
  height: 25px;
}
.icon.close::before, .icon.close::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #000;
}
.icon.close::before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.icon.close::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.icon-block {
  position: relative;
  display: inline-block;
}
.icon-block .label {
  display: none;
}

.icon-custom-arrow-right {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 3px;
  background: #fff;
  top: -9.3333333333px;
}
.icon-custom-arrow-right:before {
  position: absolute;
  top: -7px;
  right: 0;
  content: " ";
  width: 14px;
  height: 14px;
  border: 3px solid #fff;
  border-left: 0;
  border-bottom: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.scroll-transition-items {
  position: absolute;
  top: 0;
  left: 5%;
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-size: 100% auto;
}

.scroll-transition-alt-wrapper {
  position: absolute;
  height: 90vh;
  width: 100%;
  top: 0;
  left: 0;
}

.scroll-transition-items-full {
  position: fixed;
  top: 0;
  left: 0;
  width: 60%;
  width: calc(66% - 60px);
  height: 0;
  padding-top: 40%;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: bottom;
  background-color: transparent;
  background-size: 100% auto;
}

.transition-item {
  position: absolute;
  z-index: 0;
  width: 250px;
  height: 250px;
  -webkit-transition: transform 1s ease-out;
  -o-transition: transform 1s ease-out;
  transition: transform 1s ease-out;
}
.transition-item:hover {
  z-index: 200 !important;
}
.before .transition-item {
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  -o-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.transition-item.active {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.transition-item > a {
  display: block;
}
.transition-item.item-1 {
  background-color: green;
  top: 25%;
  right: 6%;
  z-index: 3;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.before .transition-item.item-1 {
  -webkit-transform: translate(0, 70%);
  -ms-transform: translate(0, 70%);
  -o-transform: translate(0, 70%);
  transform: translate(0, 70%);
}
.transition-item.item-2 {
  background-color: blue;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  position: absolute;
  top: 240px;
  left: 230px;
  z-index: 2;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}
.before .transition-item.item-2 {
  -webkit-transform: translate(0, 80%);
  -ms-transform: translate(0, 80%);
  -o-transform: translate(0, 80%);
  transform: translate(0, 80%);
}
.transition-item.item-3 {
  background-color: red;
  top: 15%;
  left: 5%;
  z-index: 1;
}
.before .transition-item.item-3 {
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  -o-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.video-background {
  position: absolute !important;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 0 !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.video-background video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.panel-grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.panel-grid .pseudo-grid {
  width: 100%;
  height: 100%;
}
.panel-grid .grid-item {
  padding: 0 80px;
  border-color: #d2d2d2;
  border-bottom: 1px solid;
  border-left: 1px solid;
  vertical-align: middle;
  width: 33%;
  z-index: 1;
}
@media screen and (max-height: 700px) {
  .panel-grid .grid-item {
    padding-bottom: 20px;
  }
}
.panel-grid .grid-item:first-child {
  border-left: 0;
}
.panel-grid .grid-row:last-child .grid-item {
  border-bottom: 0;
}
.panel-grid .grid-item-title {
  font-size: 1.125rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #58595b;
}
@media screen and (max-width: 768px) {
  .panel-grid .grid-item-title {
    font-size: 1.0625rem;
  }
}
.panel-grid .grid-item-value {
  font-size: 8.125rem;
  font-weight: bold;
  line-height: 1;
}
@media screen and (max-width: 1200px) {
  .panel-grid .grid-item-value {
    font-size: 7.625rem;
  }
}
@media screen and (max-width: 992px) {
  .panel-grid .grid-item {
    padding: 3% 4%;
  }
  .panel-grid .grid-item-value {
    font-size: 4.75rem;
    line-height: 1;
  }
}
@media screen and (max-width: 768px) {
  .panel-grid .grid-item {
    padding: 3% 2%;
  }
  .panel-grid .grid-item-value {
    font-size: 4.375rem;
  }
}

@media screen and (max-width: 992px), (orientation: portrait) {
  .grid-item-inner {
    display: none;
  }

  .grid-left .grid-row-first .grid-item:nth-child(3) {
    vertical-align: middle;
  }
  .grid-left .grid-row-first .grid-item:nth-child(3) .grid-item-inner {
    display: block;
    text-align: right;
  }
  .grid-left .grid-item:first-child {
    display: none;
  }
  .grid-left .grid-item:nth-child(2) {
    border-left: 0;
  }

  .grid-right .grid-row-first .grid-item:first-child {
    vertical-align: middle;
  }
  .grid-right .grid-row-first .grid-item:first-child .grid-item-inner {
    display: block;
    text-align: left;
  }
  .grid-right .grid-item:last-child {
    display: none;
  }
  .grid-right .grid-item:nth-child(2) {
    border-right: 0;
  }
}
.panel-content-list {
  position: relative;
  float: right;
  width: 33%;
  margin: 60px 60px 60px 0;
}
@media screen and (max-width: 992px), (orientation: portrait) {
  .panel-content-list {
    float: none;
    width: 94%;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 0;
  }
}
.panel-content-list .panel-content-list-item {
  border-top: 1px solid #626262;
  padding: 10px 0;
}
.panel-content-list .panel-content-list-item .item-inner {
  position: relative;
  z-index: 1;
}
.panel-content-list .panel-content-list-item .item-inner > .icon {
  font-size: 30px;
  position: absolute;
  right: 0;
  top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.panel-content-list .panel-content-list-item .pop-content-overlist {
  z-index: 2;
}
.panel-content-list .panel-content-list-item .pop-content-overlist .description {
  margin-bottom: 0.5em;
}
.panel-content-list .pretitle {
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
}
.panel-content-list .pretitle:not(.no-margin-bottom) {
  margin-bottom: 5px;
}
.panel-content-list .description {
  font-size: 1.375rem;
}
.panel-content-list.large-description .description {
  font-size: 2.8125rem;
}
@media screen and (max-width: 670px) {
  .panel-content-list.large-description .description {
    font-size: 2.25rem;
    font-size: 7vw;
  }
}
@media screen and (max-width: 670px) {
  .panel-content-list.large-description .pop-content-rich-text {
    font-size: 22px;
  }
}
@media screen and (min-width: 992px) and (orientation: landscape) {
  .panel-content-list.large-description {
    min-width: 550px;
  }
}
@media screen and (max-width: 768px) {
  .panel-content-list.large-description {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
.panel-content-list.interactive-list .pretitle {
  color: #e82011;
  margin-bottom: 0;
  font-weight: normal;
  font-family: "UniversLTStd-CondensedBold";
}
.panel-content-list.interactive-list .panel-content-list-item {
  border-top-color: transparent;
  padding: 0;
}
.panel-content-list.interactive-list .panel-content-list-item .item-inner {
  border-top: 1px solid #fff;
  padding: 10px 0;
}
@media screen and (min-width: 992px) and (orientation: landscape) {
  .panel-content-list.interactive-list .panel-content-list-item:last-child:not(.float-to-top) .item-inner {
    border-bottom: 1px solid #fff;
  }
}
.panel-content-list.dark-text .panel-content-list-item:not(.desktop-interaction-list-item) .pretitle {
  color: #111111;
}
.panel-content-list.dark-text .panel-content-list-item:not(.desktop-interaction-list-item) .description {
  color: #1b1b1b;
}

@media screen and (min-width: 992px) and (orientation: landscape) {
  .panel-content-list-bio {
    width: 90%;
    width: calc(90% - 60px);
  }
}
@media screen and (min-width: 992px) and (max-width: 1500px) {
  .panel-content-list-bio {
    margin-right: 2vw;
    width: calc(90% - 2vw);
  }
}
@media screen and (max-width: 992px), (orientation: portrait) {
  .panel-content-list-bio {
    margin-top: 0;
  }
}
.panel-content-list-bio .panel-content-list-item:first-child .description {
  font-size: 30px;
  font-weight: bold;
}

@media screen and (min-width: 992px) and (orientation: landscape) {
  .panel-content-list-gradient-wrapper {
    float: right;
    width: 30%;
    width: calc(
      100vw - 150.9433962264vh + 235.8490566038px
    );
    min-width: 25vw;
  }
}

html:not(.mobile-detected) .panel-content-list.min-parallax-height {
  min-height: calc(100vh - 100px);
}

.no-transform {
  transform: none !important;
}

@media screen and (min-width: 992px) and (orientation: landscape) {
  .gradient-light-text-on-dark,
.gradient-dark-text-on-light {
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }

  .gradient-light-text-on-dark {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), #000);
  }

  .gradient-dark-text-on-light {
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
  }

  .para-panel.active .gradient-light-text-on-dark,
.para-panel.active .gradient-dark-text-on-light {
    opacity: 1;
  }
}
@media screen and (min-width: 992px) and (orientation: landscape) and (min-width: 992px) and (orientation: landscape) {
  .para-panel.active .has-active-interaction .gradient-dark-text-on-light {
    background: none;
  }
}
.link-button-jumbo {
  position: relative;
  display: table-cell;
  width: 2%;
  border-top: 1px solid #626262;
  border-left: 1px solid #626262;
  background-color: #111111;
  color: #fff;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  font-size: 1.625rem;
}
.link-button-jumbo .link-content {
  display: inline-block;
  vertical-align: middle;
}
.link-button-jumbo .pretitle {
  display: block;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #e82011;
  margin-bottom: 0.5em;
}
.link-button-jumbo .icon {
  color: #e82011;
  font-size: 3em;
  vertical-align: middle;
  font-size: 4.75rem;
}
.link-button-jumbo:first-child {
  border-left: 0;
}
.link-button-jumbo:hover {
  color: #fff;
}

.active-interaction-panel .desktop-interaction-image {
  animation: interaction-pulse-from-zero 1.5s 2;
  animation-fill-mode: forwards;
}

.apply-ie-fix .active-interaction-panel .desktop-interaction-image {
  animation: interaction-pulse-from-zero 1.5s 1;
}

.desktop-interaction {
  position: absolute;
  pointer-events: auto;
  width: 50px;
  height: auto;
  -webkit-transition: transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.desktop-interaction:not(.active):hover {
  transform: scale(1.04) !important;
}
.desktop-interaction.active .desktop-interaction-image {
  animation-iteration-count: infinite;
}

.apply-ie-fix .desktop-interaction.active .desktop-interaction-image {
  animation: interaction-pulse-from-zero-for-ie 1.5s infinite;
}

.scroll-through .desktop-interaction {
  pointer-events: none !important;
}

.desktop-interaction-image {
  transform: translateZ(0);
  height: 100%;
  width: 100%;
  transition: filter 0.3s ease;
  outline: none;
}

@media screen and (min-width: 992px) and (orientation: landscape) {
  .has-active-interaction .desktop-interaction:not(.active) .desktop-interaction-image {
    filter: brightness(50%);
  }
  .apply-ie-fix .has-active-interaction .desktop-interaction:not(.active) .desktop-interaction-image {
    opacity: 0.6;
  }
}
.desktop-interaction-icon {
  color: #111111;
  font-size: 50px;
}

.light-text .desktop-interaction-icon {
  color: #fff;
}

.desktop-interaction-list {
  float: none;
  width: 100%;
  box-sizing: border-box;
}

.mobile-desktop-image-img {
  width: 100%;
  height: auto;
}

.above-desktop-interactions {
  z-index: 11 !important;
}

.desktop-interaction-fixed-wrapper {
  z-index: 10;
  pointer-events: none;
  position: fixed;
  visibility: hidden;
  width: calc(100vw - 40px);
  height: 100%;
  left: 20px;
  bottom: 0;
  box-sizing: border-box;
}

.desktop-interaction-max-width {
  max-width: 188.679245283vh;
  max-width: calc(
    188.679245283vh - 235.8490566038px
  );
}

.desktop-interaction-fixed-ratio {
  height: 0;
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding-top: 53%;
}

.desktop-anchor-top .desktop-interaction-fixed-ratio {
  top: 105px;
}

.active-interaction-panel .desktop-interaction-fixed-wrapper {
  visibility: visible;
}

.desktop-interaction-list-wrapper {
  background-color: #1b1b1b;
  padding: 0 1em 0.1em 1em;
}

.desktop-interaction-list-item {
  color: #fff;
}
.desktop-interaction-list-item .pretitle {
  margin-bottom: 0;
  margin-right: 50px;
}

.panel-content-list .desktop-interaction-list-header {
  border-top: none;
}
.panel-content-list .desktop-interaction-list-header .header {
  text-transform: uppercase;
  padding: 0.5em 0 0 0;
  color: #9b9b9b;
  display: block;
  font-size: 20px;
}

.desktop-interaction-content {
  -webkit-transition: max-height 0.3s ease-out;
  -o-transition: max-height 0.3s ease-out;
  transition: max-height 0.3s ease-out;
  max-height: 0em;
  overflow: hidden;
}

.desktop-interaction-content-padding {
  padding-top: 0.5em;
}

.desktop-interaction-expanded .desktop-interaction-content {
  max-height: 20em;
}
.desktop-interaction-expanded .desktop-interaction-list-icon {
  transform: rotate(-180deg);
}

@media screen and (min-width: 992px) and (orientation: landscape) {
  .has-active-interaction .panel-content-list-item:not(.desktop-interaction-expanded):not(.desktop-interaction-list-header) {
    opacity: 0.4;
  }
}
.desktop-interaction-list-icon {
  position: absolute;
  color: #e82011;
  justify-content: flex-start;
  right: 10px;
  font-size: 24px;
  -webkit-transition: transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
}
.desktop-interaction-list-icon:before {
  content: "\e913";
  display: inline-block;
  font-family: "skadden";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes interaction-pulse-from-zero {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes interaction-pulse-from-zero-for-ie {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
@media screen and (min-width: 992px) and (orientation: landscape) {
  .active-dim-important {
    opacity: 0.5 !important;
  }
}

.slogan-animation {
  background-image: radial-gradient(circle at top left, #474747, rgba(27, 27, 27, 0.98) 70%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-right: 43.75em;
  padding-top: 3.75em;
  padding-bottom: 2.5em;
  padding-left: 2.5em;
  transition: opacity 0.5s ease-in 1s;
  text-align: center;
}
@media screen and (max-width: 992px), (orientation: portrait) {
  .slogan-animation {
    padding-right: 3%;
    padding-left: 3%;
    padding-top: 1.25em;
  }
  .slogan-animation.animation-complete {
    opacity: 0.1;
  }
}
@media screen and (max-height: 49.375em) {
  .slogan-animation {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.svg-inline {
  fill: rgba(0, 0, 0, 0.01);
  transition: fill 10s linear;
}
.svg-inline.animation-done {
  fill: black;
}

.responsive-svg-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}
@media screen and (min-height: 49.375em) {
  .responsive-svg-container {
    height: 35.9375em;
  }
}
@media screen and (max-width: 992px), (orientation: portrait) {
  .responsive-svg-container {
    height: 100%;
  }
}

.responsive-svg-canvas {
  max-width: 36.5625em;
  width: 100%;
}

.responsive-svg {
  position: relative;
  height: 0;
  padding-top: 81.275%;
}
@media screen and (max-width: 992px), (orientation: portrait) {
  .responsive-svg {
    max-width: 43.75em;
  }
}
.responsive-svg svg {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
}
.responsive-svg #letter-fill {
  fill: #191919;
}
@media screen and (max-width: 992px), (orientation: portrait) {
  .responsive-svg #letter-fill {
    fill: #000;
  }
}
.responsive-svg #letter-fill g {
  opacity: 0;
  transition: opacity 0.75s ease-in-out 0.5s;
}
.responsive-svg #letter-outlines .animation-done {
  opacity: 1;
}
.responsive-svg #letter-fill .animation-done {
  opacity: 1;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.pop-content-list-item .item-inner {
  opacity: 0;
  transition: opacity 350ms ease-in;
}
.pop-content-list-item:nth-child(2) .item-inner {
  transition-delay: 200ms;
}
.pop-content-list-item:nth-child(3) .item-inner {
  transition-delay: 400ms;
}
.pop-content-list-item:nth-child(4) .item-inner {
  transition-delay: 600ms;
}
.pop-content-list-item:nth-child(5) .item-inner {
  transition-delay: 800ms;
}
.animation-listing .pop-content-list-item .item-inner {
  opacity: 1;
}
@media screen and (max-width: 62em) {
  .pop-content-list-item .item-inner {
    transition: opacity 350ms ease-in 1s;
  }
  .pop-content-list-item:nth-child(2) .item-inner {
    transition: opacity 350ms ease-in 1.2s;
  }
  .pop-content-list-item:nth-child(3) .item-inner {
    transition: opacity 350ms ease-in 1.4s;
  }
  .pop-content-list-item:nth-child(4) .item-inner {
    transition: opacity 350ms ease-in 1.6s;
  }
  .pop-content-list-item:nth-child(5) .item-inner {
    transition: opacity 350ms ease-in 1.8s;
  }
}

.message-items .message-item {
  color: #fff;
  font-size: 18px;
}
.message-items .message-item a {
  color: inherit;
}
.message-items .message-item .icon {
  font-size: 18px;
}
.message-items .message-item .message-content {
  padding: 20px 0;
  background-color: #e82011;
}
.message-items .message-item.alert .message-content {
  background-color: #e82011;
}
.message-items .message-item-columns {
  display: table;
}
.message-items .message-item-columns .col {
  display: table-cell;
  vertical-align: middle;
  padding-right: 10px;
}

.lawyer-careers-navigation.float-side {
  -webkit-transform: translate(-110%, 0);
  -ms-transform: translate(-110%, 0);
  -o-transform: translate(-110%, 0);
  transform: translate(-110%, 0);
  -webkit-transition: transform 0.2s ease-in;
  -o-transition: transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  z-index: 12;
}
.lawyer-careers-navigation.float-side .nav-items {
  background-color: #000;
  padding: 0;
}
.lawyer-careers-navigation.float-side .nav-item {
  display: block;
  position: relative;
  font-size: 1.125rem;
}
.lawyer-careers-navigation.float-side .nav-item:hover a, .lawyer-careers-navigation.float-side .nav-item.active a {
  border-right-color: #e82011;
}
.lawyer-careers-navigation.float-side .nav-item:last-child .itemtitle {
  min-height: 40px;
  color: #e82011;
}
.lawyer-careers-navigation.float-side .nav-item:last-child .nav-item-inner {
  padding-bottom: 10px;
}
.lawyer-careers-navigation.float-side .nav-item-inner {
  padding: 10px 10px 0 20px;
  border-right: 5px solid;
  border-right-color: transparent;
  -webkit-transition: border 0.2s ease-in;
  -o-transition: border 0.2s ease-in;
  transition: border 0.2s ease-in;
}
.lawyer-careers-navigation.float-side .pretitle {
  font-size: 0.8em;
}
.lawyer-careers-navigation.float-side .itemtitle {
  font-weight: bold;
  font-family: "Lyon Text Web";
  display: block;
  max-width: 100px;
  word-break: keep-all;
}
.side-nav-active .lawyer-careers-navigation.float-side, .lawyer-careers-navigation.float-side.active {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
@media only screen and (max-width: 1600px) {
  .side-nav-active .lawyer-careers-navigation.float-side, .lawyer-careers-navigation.float-side.active {
    -webkit-transform: translate(-100%, 0);
    -ms-transform: translate(-100%, 0);
    -o-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }
}
@media screen and (min-width: 992px) and (max-height: 815px) and (orientation: landscape) {
  .side-nav-active .lawyer-careers-navigation.float-side, .lawyer-careers-navigation.float-side.active {
    -webkit-transform: translate(-100%, 0);
    -ms-transform: translate(-100%, 0);
    -o-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }
  @supports (-webkit-overflow-scrolling: touch) {
    .side-nav-active .lawyer-careers-navigation.float-side, .lawyer-careers-navigation.float-side.active {
      -webkit-transform: translate(calc(-100% + 20px), 0);
      -ms-transform: translate(calc(-100% + 20px), 0);
      -o-transform: translate(calc(-100% + 20px), 0);
      transform: translate(calc(-100% + 20px), 0);
      bottom: 20px;
    }
  }
}
@media screen and (max-width: 992px), (orientation: portrait) {
  .lawyer-careers-navigation.float-side.active-mobile {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@media screen and (min-width: 992px) and (max-height: 815px) and (orientation: landscape) {
  .lawyer-careers-navigation.float-side.active-mobile {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@media only screen and (max-width: 1600px) {
  .lawyer-careers-navigation.float-side.active-mobile {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.apply-ie-fix .lawyer-careers-navigation.float-side .itemtitle {
  display: block;
  margin-right: 20px;
}

.mobile-side-nav-trigger {
  position: absolute;
  bottom: 0;
  left: 100%;
  width: 35px;
  height: 80px;
  background-color: #5f5f5f;
  opacity: 0;
  -webkit-transition: opacity, 0.7s;
  -o-transition: opacity, 0.7s;
  transition: opacity, 0.7s;
}
.mobile-side-nav-trigger:before {
  content: "\e914";
  display: inline-block;
  font-family: "skadden";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.mobile-side-nav-trigger::before {
  color: #fff;
  font-size: 24px;
  line-height: 80px;
  width: 100%;
  text-align: center;
}

.lawyer-careers-navigation.active .mobile-side-nav-trigger {
  opacity: 0;
}
@media only screen and (max-width: 1600px) {
  .lawyer-careers-navigation.active .mobile-side-nav-trigger {
    opacity: 1;
  }
}

.lawyer-careers-navigation.active-mobile .mobile-side-nav-trigger::before {
  transform: rotate(180deg);
}

.site-search .search-form {
  position: relative;
  border: 1px solid #979797;
  transform: translateY(-50%);
  top: 45%;
  padding: 30px;
}
.site-search .search-input {
  border-color: transparent;
  border-bottom-color: #2bb3d5;
  font-size: 3.5rem;
  line-height: 1.1;
  height: auto;
}
.site-search .icon-block-search {
  position: absolute;
  right: 0;
  top: 0;
}
.site-search .icon-block-search .icon {
  height: auto;
}

.hero .over-content {
  bottom: 0;
}
@media screen and (max-width: 992px) {
  .hero {
    max-width: 100%;
  }
  .hero:not(.no-padding) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.hero .content .title {
  font-size: 2rem;
  color: #fff;
}

.over-content {
  position: absolute;
  width: 100%;
  z-index: 100;
}

.hero-image {
  width: 100%;
}
.hero-image img {
  width: 100%;
  display: block;
}

.pop-content {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  color: #fff;
  visibility: visible;
  -webkit-transition: height 0.3s linear;
  -o-transition: height 0.3s linear;
  transition: height 0.3s linear;
}
.pop-content:not(.active) {
  transition-delay: 0s !important;
}
.pop-content .title {
  font-size: 1.125rem;
}
.pop-content .description {
  margin-top: -0.2em;
}
.pop-content > .close-icon {
  position: absolute;
  right: 22px;
  top: 22px;
  margin: 0;
  z-index: 2;
  font-size: 24px;
  cursor: pointer;
  color: #e82011;
}
.pop-content .pop-content-content {
  padding-top: 20px;
}
.pop-content .content-inner {
  padding-right: 20px;
}
.pop-content.pop-content-overlist {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
}

.pop-content-faded {
  opacity: 0;
  pointer-events: none;
}

.pop-content-list .item-inner {
  transition: none;
  opacity: 1;
}

.pop-content-list-item {
  -webkit-transition: opacity 0.2s linear, padding 0.4s ease-out, max-height 0.4s ease-out;
  -o-transition: opacity 0.2s linear, padding 0.4s ease-out, max-height 0.4s ease-out;
  transition: opacity 0.2s linear, padding 0.4s ease-out, max-height 0.4s ease-out;
  height: 115px;
  max-height: 115px;
}
.pop-content-list-item.float-to-top {
  max-height: none;
}

.singled-out .pop-content-list-item:not(.float-to-top) {
  max-height: 0;
  transition-delay: 0s !important;
  padding: 0;
}

.pop-content-rich-text {
  -webkit-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  font-size: 24px;
  margin-top: 0.5em;
}
.pop-content-rich-text.active {
  opacity: 0.8;
}

.breadcrumbs {
  height: 100%;
  padding: 0 20px;
}
.breadcrumbs .breadcrumbs-list {
  position: relative;
  top: 50%;
  margin: 0;
  padding: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.breadcrumbs .breadcrumbs-item {
  display: inline-block;
  color: #333;
  font-size: 1.125rem;
  font-weight: bold;
}
.breadcrumbs .breadcrumbs-item a {
  color: #5f5f5f;
}
.breadcrumbs .breadcrumbs-item a:hover {
  color: #333;
}

.flyout {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  right: 0;
  height: 100%;
  background-color: #1b1b1b;
  padding: 8% 5% 0;
  z-index: 3;
  max-width: 40%;
  -webkit-transform: translate(102%, 0);
  -ms-transform: translate(102%, 0);
  -o-transform: translate(102%, 0);
  transform: translate(102%, 0);
  -webkit-transition: transform 0.2s linear;
  -o-transition: transform 0.2s linear;
  transition: transform 0.2s linear;
}
.flyout.site-search {
  background-color: #fff;
}
.flyout.active {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
@media screen and (max-width: 992px) {
  .flyout {
    max-width: 100%;
  }
}

.questionnaire-question,
.question-inner {
  width: 100%;
  height: 100%;
}

.questionnaire {
  display: block;
  position: relative;
  width: 100%;
  height: calc(100vh - 61px);
  background-color: #111111;
  color: #fff;
}
.questionnaire .button-reset {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 100;
  margin-right: 40px;
  margin-bottom: 40px;
  font-weight: bold;
}
.questionnaire .button-reset:focus {
  outline: 1px thin #111111;
}
@media screen and (max-width: 768px) {
  .questionnaire .button-reset {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.questionnaire-results {
  position: absolute;
  width: auto;
  height: auto;
  left: 0;
  top: 10%;
  padding-left: 40px;
  z-index: 1000;
}
@media screen and (min-width: 992px) and (max-height: 815px) and (orientation: landscape) {
  .questionnaire-results {
    padding-left: 0;
    max-width: 25%;
    top: 60px;
  }
}
.questionnaire-results .questionnaire-results-item {
  font-size: 14px;
  font-weight: bold;
}
.questionnaire-results .questionnaire-results-item.inner {
  margin-left: 1em;
  margin-bottom: 20px;
}
@media screen and (min-width: 992px) and (max-height: 815px) and (orientation: landscape) {
  .questionnaire-results .questionnaire-results-item {
    margin-bottom: 5px;
  }
}

.questionnaire-heading {
  bottom: 50%;
}

.questionnaire-question {
  background-color: #111111;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.5s linear;
  -o-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
}
.questionnaire-question .question-inner {
  display: flex;
  flex: 0 0 auto;
  align-items: left;
  justify-content: center;
  flex-direction: column;
}
.questionnaire-question.question-started {
  z-index: 2;
  opacity: 1;
  visibility: visible;
}
.questionnaire-question .icon-custom-arrow-right {
  position: relative;
  margin-left: 0.5em;
  display: none;
  opacity: 0;
  left: 0;
  -webkit-transition: all 0.07s linear;
  -o-transition: all 0.07s linear;
  transition: all 0.07s linear;
}
.questionnaire-question .icon-custom-arrow-right {
  display: inline-block;
}
.questionnaire-question .questionnaire-step.completed .icon-custom-arrow-right {
  opacity: 1;
}
@keyframes bouncer {
  50% {
    left: 8px;
  }
}

.questionnaire-step.completed .icon-custom-arrow-right {
  -webkit-animation: bouncer 1s linear infinite;
  -o-animation: bouncer 1s linear infinite;
  animation: bouncer 1s linear infinite;
}

.questionnaire-step {
  width: 50%;
  margin: 10px auto 30px;
  padding: 0;
  opacity: 0;
  height: 0;
  overflow: hidden;
}
@media screen and (min-width: 992px) and (max-height: 815px) and (orientation: landscape) {
  .questionnaire-step {
    margin-left: 28%;
  }
}
.questionnaire-step:first-child {
  margin-top: 0;
}
.questionnaire-step.active {
  opacity: 1;
  height: auto;
}
.questionnaire-step .question-title-wrapper {
  font-size: 2.8125rem;
}
@media screen and (max-width: 992px), (orientation: portrait) {
  .questionnaire-step .question-title-wrapper {
    font-size: 1.75rem;
  }
}
.questionnaire-step .question-title {
  display: inline-block;
  font-size: inherit;
  margin-bottom: 0.3em;
  font-weight: bold;
  transition: none;
}
.questionnaire-step .question-title:hover {
  color: #fff;
}
.questionnaire-step .question-options {
  font-size: 1.5rem;
}
@media screen and (max-width: 992px), (orientation: portrait) {
  .questionnaire-step .question-options {
    font-size: 1.375rem;
  }
}
.questionnaire-step .question-options .question-option-items {
  display: block;
  margin: 0 -0.25em;
}
.questionnaire-step .question-options .question-option-item {
  position: relative;
  display: inline-block;
  padding: 0 0.25em;
  margin: 0 0.25em 0.5em;
  margin-bottom: 0.5em;
}
.questionnaire-step .question-options .question-option-item:before {
  position: absolute;
  top: 0.2em;
  left: 0;
  z-index: 1;
  content: " ";
  width: 0.15em;
  height: 80%;
  background-color: transparent;
  -webkit-transition: background-color 0.25s ease-in;
  -o-transition: background-color 0.25s ease-in;
  transition: background-color 0.25s ease-in;
}
.questionnaire-step .question-options .question-option-item:hover:before {
  background: #fff;
}
.questionnaire-step .question-options .question-option-item-title {
  padding: 0.15em 0.25em;
  color: inherit;
}
.questionnaire-step .question-options.location .question-option-item-title:after {
  vertical-align: text-top;
  font-size: 0.85em;
  margin-left: 0.5em;
  content: "|";
}
@media screen and (max-width: 992px) {
  .questionnaire-step {
    width: auto;
    margin: 0;
    padding: 0 3%;
  }
}

.cursor-type-fx {
  position: relative;
}
.cursor-type-fx .cursor-fx-item {
  position: relative;
  font-style: inherit;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.07s linear;
  -o-transition: all 0.07s linear;
  transition: all 0.07s linear;
}
.questionnaire-step.active .cursor-type-fx:before, .cursor-type-fx.active:before {
  -webkit-animation: blinker 1s linear infinite;
  -o-animation: blinker 1s linear infinite;
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.questionnaire-step.active .cursor-type-fx .cursor-fx-item, .cursor-type-fx.active .cursor-fx-item {
  opacity: 1;
  visibility: visible;
}
.questionnaire-step.completed .cursor-type-fx:before, .cursor-type-fx.complete:before, .cursor-type-fx.fx-completed.active:before, .cursor-type-fx.fx-completed:before {
  -webkit-animation-name: none;
  -o-animation-name: none;
  animation-name: none;
}
.questionnaire-step.completed .cursor-type-fx .cursor-fx-item, .cursor-type-fx.complete .cursor-fx-item, .cursor-type-fx.fx-completed.active .cursor-fx-item, .cursor-type-fx.fx-completed .cursor-fx-item {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.question-unstarted .cursor-fx-item {
  transition-delay: 0s !important;
}

.cursor-style {
  position: relative;
  padding-left: 0.5em;
}
.cursor-style:before {
  position: absolute;
  top: 0.2em;
  left: 0;
  z-index: 1;
  content: " ";
  width: 0.15em;
  height: 0.8em;
  background: #e82011;
}
.cursor-style.white:before {
  background-color: #fff;
}

.question-title {
  color: white;
  font-size: 45px;
  font-weight: bold;
}
.question-conclusion a {
  color: white;
  font-size: 45px;
  font-weight: bold;
}

.nav-bar--horizontal {
  padding: 1em;
}
.nav-bar--horizontal:after {
  clear: both;
}
.nav-bar--horizontal:before, .nav-bar--horizontal:after {
  display: table;
  content: " ";
}

.nav-bar__logo {
  display: inline-block;
  margin: 0;
}

.nav-bar__nav {
  float: right;
}
.nav-bar__nav .list__item {
  line-height: 2;
}

.article__header {
  margin-bottom: 1em;
}

.article__lead {
  font-size: 120%;
}

.site-footer {
  position: relative;
  width: 100%;
  z-index: 2;
  padding: 0;
  background-color: #333;
  color: #fff;
}
.site-footer .title {
  color: #fff;
}
.site-footer .content-wrapper {
  padding: 40px 2.5em 0 2.5em;
  margin: 0 auto;
}
.site-footer a {
  color: inherit;
}
.site-footer a:hover {
  text-decoration: underline;
}
.para-container .site-footer {
  height: 0;
  overflow: hidden;
}
@media screen and (max-width: 992px) {
  .site-footer {
    padding: 20px 0;
  }
}

.site-footer-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.site-footer-menu-link {
  text-transform: uppercase;
  font-size: 1.125em;
  display: inline-block;
  line-height: 1.2;
  margin-right: 0.25em;
}

.site-footer-utility-link {
  text-transform: uppercase;
  padding-right: 2.5em;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.5em;
  line-height: 1.2;
  vertical-align: bottom;
}

.site-footer-additional {
  padding-top: 1.5625em;
  padding-bottom: 0;
  margin-top: 30px;
  border-top: 1px solid #fff;
  overflow: hidden;
}

.site-footer .social-links-icon {
  font-size: 1.625em;
  margin-left: 0.5em;
}

@media only screen and (max-width: 850px) {
  .site-footer-menu {
    display: block;
  }
  .site-footer-menu-item {
    flex: 1 1 100%;
  }

  .site-footer-utility-item {
    margin-right: 0;
  }

  .site-footer-utility-link {
    padding-right: 0;
  }
}
.block-section {
  position: relative;
  margin: 0 20px;
}
@media screen and (max-width: 992px) {
  .block-section {
    margin-bottom: 0;
  }
}

.section-title {
  font-size: 1.125rem;
  color: #333;
}
@media screen and (max-width: 768px) {
  .section-title {
    text-align: center;
  }
}

.more-link-wrapper {
  margin: 20px 0;
  text-align: center;
}

.more-link .icon {
  margin-left: 0.5em;
  font-size: 1.3em;
}

.content-narrow {
  width: 60%;
  margin: 0 auto;
}
@media screen and (max-width: 992px) {
  .content-narrow {
    width: 80%;
  }
}

.page-home .block-section {
  background: #1b1b1b;
}

.main-para,
.para-container,
.para-bg-fixer,
.para-bg-fixer-content,
.para-panel-background {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.para-bg-fixer,
.para-container {
  overflow: auto;
  background-color: #000;
}
@media screen and (min-width: 768px) {
  .para-bg-fixer,
.para-container {
    /*padding: $spacing;
    padding-top: $header-height + $spacing;*/
  }
  .stick-float-nav .para-bg-fixer,
.stick-float-nav .para-container {
    padding-right: 0;
  }
}
@supports (-webkit-overflow-scrolling: touch) {
  @media screen and (max-width: 992px), (orientation: portrait) {
    .para-bg-fixer,
.para-container {
      padding: 60px 0 0;
    }
  }
  @media screen and (min-width: 992px) and (max-height: 815px) and (orientation: landscape) {
    .para-bg-fixer,
.para-container {
      padding-top: 0;
    }
  }
}

.para-container {
  z-index: 0;
}

.para-bg-fixer {
  z-index: 1;
  position: absolute;
  overflow: hidden;
  background-color: transparent;
}

.para-bg-fixer-content .video-background {
  opacity: 1;
  -webkit-transition: opacity 0.6s ease;
  -o-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}
.para-bg-fixer-content .video-background.has-video {
  transition: none;
}

.close-video .para-bg-fixer-content .video-background {
  opacity: 0;
}

.absolute-bio-background {
  position: absolute;
  background-origin: content-box;
  box-sizing: border-box;
  background-clip: content-box;
}
@media screen and (min-width: 768px) {
  .absolute-bio-background {
    padding: 20px;
    padding-top: 105px;
  }
}

.absolute-bio-desktop {
  background-size: contain;
  position: absolute;
  left: 0;
  background-repeat: no-repeat;
}
.absolute-bio-desktop.desktop-anchor-bottom {
  background-position: left bottom;
  bottom: 0;
}
.absolute-bio-desktop.desktop-anchor-top {
  background-position: left top;
  top: 0;
}

.para-panels {
  position: relative;
  width: 100%;
  padding: 0;
  overflow: -moz-scrollbars-none;
  height: 100%;
  overflow: hidden;
  -ms-overflow-y: auto;
  overflow-y: auto;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}
.para-panels::-webkit-scrollbar {
  width: 0 !important;
}
@supports (-webkit-overflow-scrolling: touch) {
  .para-panels {
    z-index: 1;
  }
}

.para-panel {
  position: relative;
  left: 0;
  top: 0%;
  height: 100%;
  width: 100%;
  z-index: 1;
}
@media screen and (min-width: 992px) and (orientation: landscape) {
  .para-panel {
    background-image: none !important;
  }
}
.para-panel#parallax-item-1 {
  overflow: hidden;
}
.para-panel#parallax-item-1 .para-panel-content {
  overflow: visible;
}
@media screen and (max-width: 992px), (orientation: portrait) {
  .para-panel#parallax-item-1 {
    min-height: 100vh;
  }
}
.para-panel#parallax-item-2 {
  height: auto;
  z-index: 5;
  overflow: hidden;
  height: 100%;
}
.para-panel#parallax-item-2 .para-panel-content-inner {
  display: flex;
  align-items: center;
}
.para-panel.full-size {
  min-height: 100%;
  height: auto;
}
.para-panel.para-panel-questionnaire {
  z-index: 12;
}

@media screen and (max-width: 992px), (orientation: portrait) {
  .dark-background-mobile {
    background-color: #111111;
  }

  .light-background-mobile {
    background-color: #fff;
  }
}
.para-panel-spacer {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.para-panel-inner {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: -6px;
  padding-top: 6px;
  background-color: transparent;
}
.dark .para-panel-inner {
  background-color: #111111;
}
.light .para-panel-inner {
  background-color: #fff;
}

.para-panel-layer {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.para-panel-background {
  position: absolute;
  z-index: 1;
}

.para-panel-content {
  margin: 0;
  padding: 0;
  z-index: 2;
  min-width: 100%;
  max-width: 100%;
  overflow: hidden;
  opacity: 1;
  color: #fff;
}
.light .para-panel-content {
  color: #1b1b1b;
}

.para-panel-content-inner {
  height: 100%;
  min-width: 100%;
  max-width: 100%;
}

.panel-vertical-center-title {
  display: table;
  position: relative;
  height: 100%;
}
.panel-vertical-center-title > h1 {
  display: table-cell;
  padding: 0;
  margin: 0;
  vertical-align: middle;
}

.panel-featured-title {
  max-width: 55%;
  margin: 0 auto;
}
.panel-featured-title > h1 {
  font-weight: bold;
  text-align: center;
  margin: 0;
  line-height: 1.2em;
  font-size: 3.5rem;
}
.panel-featured-title em {
  font-style: normal;
  color: #e82011;
  font-size: 3.5rem;
}
@media screen and (max-width: 1200px) {
  .panel-featured-title {
    max-width: 65%;
  }
}
@media screen and (max-width: 992px) {
  .panel-featured-title {
    max-width: 75%;
    padding: 100px 0;
  }
}
@media screen and (max-width: 768px) {
  .panel-featured-title {
    max-width: 90%;
    padding: 6vh 0;
  }
  .panel-featured-title > h1,
.panel-featured-title em {
    font-size: 3.5rem;
    font-size: 7.5vw;
  }
}

.panel-figure {
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  z-index: 5;
}
.panel-figure .image-responsive {
  height: 100%;
  box-sizing: border-box;
  position: relative;
}
.panel-figure .desktop {
  position: absolute;
  height: 100%;
  width: auto;
  bottom: 0;
}
@media screen and (min-width: 768px) {
  .panel-figure .desktop {
    height: 100% !important;
  }
}
@media (min-width: 992px) and (max-width: 1500px) {
  .panel-figure .desktop {
    max-width: 64.2857142857vh;
    max-width: calc(71.4285714286vh - 110px);
  }
}
.panel-figure.left-side {
  left: 0;
  bottom: 0;
}
.panel-figure.left-side .desktop {
  left: 0;
}
@media screen and (max-width: 992px), (orientation: portrait) {
  .panel-figure.left-side .desktop {
    left: 0 !important;
  }
}
.panel-figure.right-side {
  right: 0;
  bottom: 0;
}
.panel-figure.right-side .desktop {
  right: 0;
}
@media screen and (max-width: 992px), (orientation: portrait) {
  .panel-figure.right-side .desktop {
    right: 0 !important;
  }
}
@media screen and (max-width: 992px), (orientation: portrait) {
  .panel-figure {
    padding-top: 150px;
  }
}

.panel-jumbo-links {
  position: absolute;
  display: table;
  width: 100%;
  height: 35%;
  bottom: 0;
  left: 0;
}

.panel-bio-name {
  position: absolute;
  top: 0;
  width: 100%;
  height: 30%;
  padding: 4vh 30% 0 30%;
  text-align: center;
}
@media screen and (max-width: 992px), (orientation: portrait) {
  .panel-bio-name {
    padding: 10px 0;
    padding-bottom: 10px;
    height: auto;
    background-color: #fff;
    z-index: 1;
  }
}
@media screen and (min-width: 992px) and (max-height: 815px) and (orientation: landscape) {
  .panel-bio-name {
    padding-top: 5px;
  }
}

.panel-bio-name-content {
  display: inline-block;
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  padding: 0.1em 0.6em;
  border: 3px solid #e82011;
  border-radius: 3px;
  color: #5f5f5f;
}
@media screen and (max-width: 992px), (orientation: portrait) {
  .panel-bio-name-content {
    border: none;
  }
}
@media screen and (min-width: 992px) and (max-height: 815px) and (orientation: landscape) {
  .panel-bio-name-content {
    font-size: 20px;
    border: none;
  }
}

.page-home {
  background-color: #1b1b1b;
}

body:before {
  padding-top: 0;
}

.profile {
  position: relative;
  overflow: hidden;
  z-index: 2;
}
.profile__panel:focus {
  outline: thin dotted;
}
.profile__mobile-heading {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  padding-bottom: 60%;
}
.profile__heading-title {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.625em;
  width: 100%;
  margin-bottom: 0;
  text-transform: uppercase;
}
.profile__heading-subtitle {
  font-family: "UniversLTStd-LightCn";
  font-size: 1.625em;
  line-height: 1.1;
  text-transform: uppercase;
}
.profile__heading-subtitle a {
  color: #e82011;
}
.profile__heading-subtitle a:hover {
  color: #000;
}
.profile__content {
  border: 1px dashed #111111;
  border-width: thin 0;
  padding: 2em 0;
}
.profile__background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -3;
  background: #fff no-repeat center;
  background-size: cover;
  transform: translateZ(0);
}
.profile__photo {
  position: absolute;
  top: 0;
  width: 90%;
  z-index: -2;
  transform: translateZ(0);
}
.profile--right-align .profile__photo {
  left: auto;
  right: 0;
}
.profile__connections {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
}
.profile__connection {
  max-width: 140px;
  flex: 1 1 140px;
  margin-right: 9px;
  margin-bottom: 9px;
  position: relative;
  overflow: hidden;
}
.profile__connection:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 4px solid transparent;
  transition: border-color 0.25s;
}
.profile__connection--is-active:after {
  border-color: #e82011;
}
.profile__connection-image {
  background: #fff no-repeat center;
  background-size: cover;
  width: 100%;
  padding-bottom: 100%;
}
.profile__connection-message {
  width: 100%;
  color: #DB4535;
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: 1em;
}
.profile__body {
  margin: 20px;
}
.profile__heading {
  padding-top: 30px;
}
.profile__container {
  background-color: #fff;
  padding: 0.1em 1em 2em;
}
@media screen and (min-width: 992px) {
  .profile {
    min-height: calc(100vh - 3.8125em);
  }
  .profile__connection {
    max-width: 140px;
    flex: 1 1 calc(25% - 9px);
  }
  .profile:before {
    content: "";
    display: block;
    height: 100%;
    width: 55vw;
    position: absolute;
    left: calc(50% - 2em);
    top: 0;
    z-index: -1;
  }
  .profile--right-align:before {
    left: auto;
    right: calc(50% - 2em);
  }
  .profile__heading {
    display: block;
    padding-top: 20px;
  }
  .profile__heading-title {
    font-size: 3em;
  }
  .profile__mobile-heading {
    position: static;
    padding: 0;
    display: block;
    min-height: auto;
  }
  .profile__mobile-heading .profile__heading-title, .profile__mobile-heading .profile__heading-subtitle {
    display: none;
  }
  .profile__connection-message {
    font-size: 1.25em;
  }
  .profile__container {
    background-color: transparent;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    min-height: 100%;
    padding: 5vh 2.5em 1.875em;
  }
  .profile__panel-frame {
    margin: 0;
  }
  .profile__content {
    margin-top: 1.875em;
    padding: 1.875em 0;
  }
  .profile__panel-frame, .profile__column {
    margin: 0;
    width: 47%;
    margin-left: 53%;
    position: relative;
  }
  .profile__photo-frame {
    position: absolute;
    left: 0;
    width: 50%;
    top: 0;
    z-index: -2;
    height: calc(100vh - 3.8125em);
  }
  .profile--sticky .profile__photo-frame {
    position: fixed;
    top: 3.8125em;
  }
  .profile--bottom-position .profile__photo-frame {
    position: absolute;
    top: auto;
    bottom: 0;
  }
  .profile__photo {
    object-fit: contain;
    object-position: bottom left;
    width: 100%;
    height: 100%;
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
    max-width: none;
  }
  .profile--right-align .profile__mobile-heading {
    padding: 0;
  }
  .profile--right-align .profile__heading {
    padding-right: 0;
    padding-left: 0;
  }
  .profile--right-align .profile__photo {
    object-position: bottom right;
    right: auto;
    left: 0;
  }
  .profile--right-align .profile__photo-frame {
    right: 0;
    left: auto;
  }
  .profile--right-align .profile__panel-frame, .profile--right-align .profile__column {
    margin-left: 0%;
  }
  .profile__connection:hover:after {
    border-color: #000;
  }
  .profile__connection--is-active:hover:after {
    border-color: #e82011;
  }
  .profile__mobile-only {
    display: none;
  }
  .profile:not(.profile__mobile-only) {
    display: block;
  }
}
@media screen and (min-width: 1500px) {
  .profile__photo {
    left: auto;
    right: 0;
    width: 100%;
    height: 100%;
    bottom: -3.8125em;
    top: auto;
  }
  .profile--right-align .profile__photo {
    right: auto;
    left: 0;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .profile__photo {
    top: auto;
    bottom: 0;
    height: 75%;
    left: 30%;
    transform: translateX(-50%);
  }
  .profile--right-align .profile__photo {
    right: auto;
    left: 80%;
  }
}

.prominent-message {
  background-color: #000;
  text-align: center;
  padding: 3.125em 2.5em;
  color: #fff;
  position: relative;
  z-index: 2;
  transform: translateZ(0);
}
.prominent-message__author {
  font-family: "UniversLTStd-CondensedBold";
  text-transform: uppercase;
}
.prominent-message__author:before {
  content: "— ";
}
.prominent-message__title {
  font-family: "UniversLTStd-LightCn";
  text-transform: uppercase;
  line-height: 1.1;
}
.prominent-message__cite {
  display: block;
  margin-top: 0.625em;
  font-style: normal;
}
.prominent-message__quote {
  font-family: Lyon Text Web, serif;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.2;
}
.prominent-message__quote:before {
  content: "“";
}
.prominent-message__quote:after {
  content: "”";
}
.prominent-message__quote, .prominent-message__cite {
  opacity: 0;
  transition: opacity 0.9s ease-in;
}
.prominent-message__cite {
  transition-delay: 0.2s;
}
.prominent-message__fadein--animate .prominent-message__quote, .prominent-message__fadein--animate .prominent-message__cite {
  opacity: 1;
}
.prominent-message__blockquote {
  max-width: 67.375em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 992px) {
  .prominent-message {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    padding-top: 7.5em;
    padding-bottom: 7.5em;
  }
  .prominent-message__blockquote {
    width: 100%;
  }
  .prominent-message__author, .prominent-message__title {
    font-size: 1.5em;
  }
  .prominent-message__quote {
    font-size: calc(35px + 6 * (100vw - 770px) / 830);
  }
  .prominent-message__cite {
    margin-top: 1.25em;
  }
}

.experience-connection__border {
  border: thin solid #111111;
  border-width: thin 0 0;
  padding-top: 1.5em;
  margin-top: 1.5em;
}
.experience-connection__border + .experience-connection__border {
  border-width: thin 0 0;
}
.experience-connection__border:last-child {
  border-bottom-width: thin;
  padding-bottom: 1.5em;
  margin-bottom: 1.5em;
}
.experience-connection__border-top {
  border-top: thin solid #111111;
  padding-top: 1.5em;
  margin-top: 1.5em;
}
.experience-connection__header {
  margin-bottom: 1em;
}
.experience-connection__header--addition {
  margin-top: 2em;
}
.experience-connection__back-track {
  padding-top: 2em;
  margin-bottom: 2em;
}
.experience-connection__title {
  font-family: "UniversLTStd-CondensedBold";
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 0.2em;
  font-size: 1.125em;
}
.experience-connection__subtitle {
  font-size: 1.125em;
  line-height: 1.1;
}
.experience-connection__closing {
  margin-top: 0.2em;
  font-size: 1.125em;
}
.experience-connection__closing:before {
  content: "— ";
}
.experience-connection__closing-link {
  margin-top: 0.2em;
}
.experience-connection__link {
  color: #DB4535;
  text-transform: uppercase;
}
.experience-connection__link:after {
  content: "\e914";
  display: inline-block;
  font-family: "skadden";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left: 0.25em;
  position: absolute;
  margin-top: 0.1em;
}
.experience-connection__link--back {
  padding-left: 0;
  text-transform: uppercase;
}
.experience-connection__link--back:before {
  content: "\e914";
  display: inline-block;
  font-family: "skadden";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0;
  padding-left: 0;
  position: relative;
  margin-top: 0;
  transform: rotateY(180deg) translateX(15%);
}
.experience-connection__link--back:after {
  display: none;
}
.experience-connection__link--plus:after {
  content: "\eb0a";
  display: inline-block;
  font-family: "skadden";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left: 0.5em;
}
.experience-connection__copy {
  font-family: Lyon Text Web, serif;
  font-size: 1.125em;
  overflow: hidden;
}
.experience-connection__copy underline {
  font-family: inherit;
}
.experience-connection__copy em {
  font-family: inherit;
  font-style: italic;
}
.experience-connection__copy strong {
  font-family: inherit;
  font-style: bold;
}
.experience-connection__copy .rte-blockquote {
  font-family: Lyon Text Web, serif;
  font-size: 1.2em;
}
.experience-connection__image {
  background-repeat: no-repeat;
  background-size: contain;
  height: 370px;
}
.experience-connection__media {
  margin: 1.5em 0;
}
.experience-connection__media-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
.experience-connection__media iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.experience-connection__media iframe,
.experience-connection__media object {
  max-width: 100%;
  display: block;
}
.experience-connection__media video {
  width: 100%;
}
.experience-connection__media + .experience-connection__closing-link {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}
.experience-connection__media:not(:last-child) {
  border-bottom: 0;
}
@media screen and (min-width: 992px) {
  .experience-connection__border {
    padding-top: 2em;
    margin-top: 2em;
  }
  .experience-connection__border:last-child {
    border-bottom-width: thin;
    padding-bottom: 2em;
    margin-bottom: 2em;
  }
  .experience-connection__border-top {
    padding-top: 2em;
    margin-top: 2em;
  }
  .experience-connection__header {
    margin-bottom: 2em;
  }
  .experience-connection__closing, .experience-connection__link {
    font-size: 1.25em;
  }
  .experience-connection__title, .experience-connection__copy {
    font-size: 1.5em;
  }
}
.experience-connection .careers-float-left,
.experience-connection .careers-float-right {
  float: left;
  max-width: 20%;
  margin-right: 16px;
  margin-bottom: 5px;
  height: auto !important;
  display: block;
}
.experience-connection .careers-float-left > img,
.experience-connection .careers-float-right > img {
  max-width: 100%;
  display: block;
  height: auto !important;
  display: block;
}
.experience-connection .careers-float-left--gray,
.experience-connection .careers-float-right--gray {
  float: left;
  max-width: 20%;
  margin-right: 16px;
  margin-bottom: 5px;
  height: auto !important;
  display: block;
  overflow: hidden;
  border-radius: 100%;
}
.experience-connection .careers-float-left--gray > img,
.experience-connection .careers-float-right--gray > img {
  max-width: 100%;
  display: block;
  height: auto !important;
  display: block;
  filter: grayscale(1);
}
.experience-connection .careers-float-right,
.experience-connection .careers-float-right--gray {
  float: right;
  margin-left: 16px;
  margin-right: 0;
}

.fadeable {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease-in, transform 0.35s ease-in;
}
.fadeable--animate {
  opacity: 1;
  transform: translateY(0);
}

.careers-hero {
  position: relative;
  overflow: hidden;
}
.careers-hero__header {
  position: relative;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: calc(250px + 30px);
  padding-bottom: 1.4375em;
}
.careers-hero__body {
  width: 100%;
  background-color: #fff;
}
.careers-hero__profile-photo {
  position: absolute;
  top: 0;
  left: 0;
  height: 250px;
  width: 100%;
  background: no-repeat center;
  background-size: cover;
  background-position: 50% 30%;
}
.careers-hero__headline {
  font-family: "UniversLTStd-CondensedBold";
  text-transform: uppercase;
  color: #e82011;
  font-size: 2.5em;
  line-height: 1;
  margin-bottom: 0.25em;
  position: relative;
  z-index: 1;
}
.careers-hero__brief {
  font-family: Lyon Text Web, serif;
  font-size: 1.25em;
  line-height: 1.2;
  position: relative;
  z-index: 1;
}
.careers-hero__brief--mobile {
  display: block;
}
.careers-hero__brief--desktop {
  display: none;
}
.careers-hero__detail-content {
  display: block;
  position: relative;
  z-index: 1;
  flex: 1 1 auto;
}
.careers-hero__person-details {
  padding: 1em 1.5em;
  line-height: 1.2;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
}
.careers-hero__person-title {
  font-family: "UniversLTStd-CondensedBold";
}
.careers-hero__call-to-action {
  background-color: #e82011;
  color: #fff;
  font-size: 1em;
  font-family: "UniversLTStd-CondensedBold";
  text-transform: uppercase;
  line-height: 1;
  text-align: left;
  width: 100%;
  border: 0;
}
.careers-hero__call-to-action-label {
  display: block;
  padding: 0.75em;
}
.careers-hero__call-to-action, .careers-hero__connections {
  width: 100%;
  align-self: flex-end;
}
.careers-hero__connections {
  display: flex;
}
.careers-hero__connection {
  width: 25%;
  height: 100%;
  padding-bottom: 20%;
  flex-shrink: 0;
  background: no-repeat center;
  background-size: cover;
  position: relative;
}
.careers-hero__connection--spacer {
  width: auto;
  flex-grow: 1;
}
.careers-hero__scroll-down {
  display: none;
}
.careers-hero__panel:focus {
  outline: thin dotted;
}
.careers-hero__animation-connection {
  transition: none;
}
@media screen and (min-width: 670px) {
  .careers-hero__header {
    position: static;
  }
  .careers-hero__tablet-header {
    position: relative;
  }
  .careers-hero__person-details {
    padding-left: 0;
  }
  .careers-hero__profile-photo {
    height: 250px;
  }
}
@media screen and (min-width: 768px) {
  .careers-hero {
    position: sticky;
    top: 3.8125em;
  }
  .careers-hero__header {
    padding-left: calc(46% + 2em);
    padding-right: 1.5em;
    padding-top: 1.875em;
    padding-bottom: 1.4375em;
  }
  .careers-hero__profile-photo {
    width: 46%;
    height: 100%;
  }
  .careers-hero__body {
    padding-top: 2.8125em;
  }
  .careers-hero__headline {
    font-size: calc(60px + 28 * (100vw - 770px) / 830);
    margin-bottom: 0;
  }
  .careers-hero__brief {
    font-size: 1.5em;
    margin-top: 1.25em;
  }
  .careers-hero__person-details {
    margin-top: 1.875em;
  }
  .careers-hero__scroll-down {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-transform: uppercase;
    height: 3.75em;
    text-align: center;
    padding-bottom: 10px;
    z-index: 1;
  }
  .careers-hero__scroll-down:after, .careers-hero__scroll-down:before {
    content: "";
    width: 35px;
    border-top: thin solid #fff;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
  }
  .careers-hero__scroll-down:before {
    transform: translateX(-100%) rotate(15deg);
  }
  .careers-hero__scroll-down:after {
    transform: rotate(-15deg);
  }
}
@media screen and (min-width: 992px) {
  .careers-hero {
    min-height: calc(100vh - 3.8125em);
  }
  .careers-hero__body {
    --shade-height: 100%;
    padding-left: 50%;
    padding-right: 0;
    padding-bottom: 180px;
    flex-direction: column;
    height: calc(100vh - 3.8125em);
  }
  .careers-hero__body::before {
    content: "";
    background-color: #e82011;
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: var(--shade-height);
    z-index: 2;
  }
  .careers-hero__body::after {
    content: "";
    background-color: #e82011;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    height: var(--shade-height);
    z-index: 2;
  }
  .careers-hero__header {
    flex-shrink: 0;
    position: static;
    padding: 0;
  }
  .careers-hero__tablet-header {
    position: static;
    z-index: 1;
    padding: 0;
    min-height: calc(100vh - 2.8125em - 20vh - 61px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .careers-hero__profile-photo {
    width: 50%;
  }
  .careers-hero__person-details {
    padding-left: clamp(24px, 5vw, 65px);
    padding-right: 10%;
    margin-top: 1.875em;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    flex-wrap: wrap;
    padding-bottom: 1em;
    opacity: 0;
    transform: translateY(1em);
  }
  .careers-hero__person-title, .careers-hero__person-subtitle {
    width: 100%;
    opacity: 0;
    transform: translateY(1em);
    display: block;
  }
  .careers-hero__person-subtitle {
    font-family: "UniversLTStd-Condensed";
    display: inline-block;
    font-size: 90%;
  }
  .careers-hero__brief {
    font-size: clamp(20px, 2vw, 24px);
    margin-top: 0;
    max-width: 100%;
    opacity: 0;
    padding-left: clamp(24px, 5vw, 65px);
    padding-top: 30px;
    padding-right: 10%;
    transform: translateY(0.5em);
  }
  .careers-hero__brief--desktop {
    display: block;
  }
  .careers-hero__brief--mobile {
    display: none;
  }
  .careers-hero__connections {
    height: 20vh;
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    width: 50%;
  }
  .careers-hero__connection {
    opacity: 0;
  }
  .careers-hero__call-to-action {
    position: absolute;
    right: 50%;
    bottom: 0;
    width: 215px;
    height: 20vh;
    font-size: 1.25em;
    display: inline-flex;
    opacity: 0;
  }
  .careers-hero__call-to-action-label {
    padding: 1.25em;
    background-color: #e82011;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 20vh;
    opacity: 1;
  }
  .careers-hero__headline {
    font-size: clamp(60px, 6vw, 85px);
    padding-left: clamp(24px, 5vw, 65px);
    padding-top: clamp(24px, 5vw, 65px);
    padding-right: 10%;
    opacity: 0;
    transform: translateY(0.5em);
  }
  .careers-hero__header, .careers-hero__person-details {
    max-width: 700px;
  }
  .careers-hero__body {
    padding-bottom: 20vh;
  }
  .careers-hero__connections {
    height: 20vh;
    max-height: 215px;
  }
  .careers-hero__call-to-action {
    font-size: 1.375em;
    max-height: none;
  }
}
@media screen and (min-width: 1200px) {
  .careers-hero__brief {
    padding-right: 10%;
  }
}
@media screen and (min-width: 992px) and (max-height: 815px) and (orientation: landscape) {
  .careers-hero__headline {
    font-size: calc(2.5em + 2vh);
  }
  .careers-hero__brief {
    font-size: 1.25em;
    max-width: 29em;
  }
  .careers-hero__person-details {
    margin-top: 1vh;
  }
}

.careers-layout {
  margin-top: 3.75em;
  margin-bottom: 0;
  z-index: 3;
}
@media screen and (min-width: 768px) {
  .careers-layout {
    margin-top: 3.75em;
  }
}

.lawyer-careers-navigation.top-nav {
  transform: translateY(-100%);
  transition: transform 0.25s ease-in;
  max-width: none;
}
.lawyer-careers-navigation.top-nav.active {
  transform: translateY(0%);
}
@media screen and (min-width: 768px) {
  .lawyer-careers-navigation.top-nav {
    transform: translateY(0%);
  }
}