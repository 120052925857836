.fadeable {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease-in,
    transform 0.35s ease-in;

  &--animate {
    opacity: 1;
    transform: translateY(0);
  }
}