//SITEWIDE PAGE LAYOUT HELPERS
.site-wrapper {
  position: relative;
  min-height: 100%;
  padding-bottom: $footer-height;

  &.no-footer {
    padding-bottom: 0;
  }

  @include max-width-md {
    padding-bottom: 0;
  }

  .page-home & {
    height: 100%;
  }
}

.main {
  position: relative;
  clear: both;
  z-index: $z-index-main;
  margin-bottom: $spacing * 2;

  @include max-width-md {
    margin-bottom: $spacing;
  }

  &.main-home {
    display: block;
    margin: 0;
  }
}

.site-boundary {
  width: 100%;
  max-width: $screen-md;
  margin: 0 auto;
  @extend .clearfix;

  &.wide {
    max-width: 100%;
  }

  @include max-width-md {
    max-width: 100%;

    &:not(.wide) {
      padding-left: $spacing;
      padding-right: $spacing;
    }
  }

  @include max-width-sm {
    &.mobile-wide {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

//general content wrapper
.content {
  @extend .clearfix;
}

//COLUMNS USING SUSSY
.columns {
  @include container();
  @extend .clearfix;

  .col {
    margin-bottom: gutter($spacing);
  }

  &.columns-alternate,
  &.columns-2 {
    .col {
      @include span(6);
      &:nth-child(even) {
        margin-right: 0;
      }

      @include max-width-sm() {
        @include span(12 break);
        &:nth-child(even) {
          @include span(12 break);
        }
      }
    }
  }

  &.columns-3 {
    .col {
      @include span(4 of 12);
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(3n + 1) {
        @include break;
      }

      @include max-width-md() {
        @include span(6);
        &:nth-child(3n) {
          @include span(6);
        }
        &:nth-child(3n + 1) {
          @include span(6);
          @include nobreak;
        }
        &:nth-child(even) {
          margin-right: 0;
        }
      }

      @include max-width-sm() {
        @include span(12 of 12 break);
        &:nth-child(3n) {
          @include span(12);
        }
        &:nth-child(3n + 1) {
          @include span(12);
        }
        &:nth-child(even) {
          @include span(12);
        }
      }
    }
  }
}

.pseudo-grid {
  display: table;

  .grid-item {
    display: table-cell;
  }

  .grid-row {
    display: table-row;
  }
}
