@use "sass:math";

.site-search {
  .search-form {
    position: relative;
    border: 1px solid $color-grey-four;
    transform: translateY(-50%);
    top: 45%;
    padding: ($spacing + math.div($spacing, 2));
  }

  .search-input {
    border-color: transparent;
    border-bottom-color: $color-teal;
    @include font-size($font-size-xxxxl);
    line-height: 1.1;
    height: auto;
  }

  .icon-block-search {
    position: absolute;
    right: 0;
    top: 0;
    .icon {
      height: auto;
    }
  }
}
