.clearfix {
  @include clearfix;
}
.center-block {
  @include center-block;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hidden {
  display: none !important;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}

//hide an element without disrupting dom spacing,
//  but allow css opacity animation (display:none will prevent css animation)
.pseudo-hidden {
  overflow: hidden !important;
  visibility: hidden !important;
  padding: 0 !important;
  height: 0 !important;
  width: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
}

.pseudo-hidden-height {
  overflow: hidden !important;
  padding: 0 !important;
  height: 0 !important;
}

//clear list decorations
.reset-list,
.reset-list ol,
.reset-list ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0;
    padding: 0;
  }
}

.image-responsive {
  //images by size
  & > .desktop {
    display: block;
  }
  & > .mobile {
    display: block;
  }
  & > .desktop + .mobile {
    display: none;
  }

  @include max-width-sm() {
    //images by size
    &.with-mobile {
      .mobile {
        display: block;
      }
      .desktop {
        display: none;
      }
    }
  }
}

.background-full {
  background-size: cover;
  background-position: center;
  background-color: transparent;
  background-repeat: no-repeat;
}

.background-opacity-transition {
  transition: opacity 0.3s ease;
}

.util-no-border {
  border: none !important;
}

.mobile-flex-column {
  @include careers-mobile-only {
    display: flex;
    flex-direction: column;
  }
}

.mobile-flex-order-1 {
  @include careers-mobile-only() {
    order: 1;
  }
}

.mobile-flex-order-2 {
  @include careers-mobile-only() {
    order: 2;
  }
}

.careers-mobile-only {
  @include careers-desktop-only() {
    display: none !important;
  }
}

.careers-desktop-only {
  @include careers-mobile-only() {
    display: none !important;
  }
}

.careers-display-on-short-height {
  @include careers-short-height-only {
    display: block !important;
    display: initial !important;
  }
}
