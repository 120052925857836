.article {
}

.article__header {
  margin-bottom: 1em;
}

.article__title {
}

.article__lead {
  font-size: 120%;
}
