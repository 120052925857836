.careers-layout {
    $nav-height: 45px;
    margin-top: px-to-em($site-header-height-mobile);
    margin-bottom: 0;
    z-index: 3;

    @include min-width-sm {
        margin-top: px-to-em(60px);
    }
}

// This careers site is awful, let's make it worse
.lawyer-careers-navigation.top-nav {
    transform: translateY(-100%);
    transition: transform 0.25s ease-in;
    max-width: none;

    &.active {
        transform: translateY(0%);
    }
    @include min-width-sm {
        transform: translateY(0%);
    }
}