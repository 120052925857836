$animationCurve: cubic-bezier(0.76, 0, 0.24, 1);
$spf: .5s; // seconds per frame

.careers-hero {
    // min-height: 100vh;
    position: relative;
    overflow: hidden;
    $mobile-image-width: 100%;
    $mobile-image-height: 250px;

    &__header {
        position: relative;
        padding-left: px-to-em(24px);
        padding-right: px-to-em(24px);
        padding-top: calc(#{$mobile-image-height} + 30px);
        padding-bottom: px-to-em(23px);
    }

    &__body {
        width: 100%;
        background-color: $color-white;
    }

    &__profile-photo {
        position: absolute;
        top: 0;
        left: 0;
        height: $mobile-image-height;
        width: $mobile-image-width;
        background: no-repeat center;
        background-size: cover;
        background-position: 50% 30%;
    }

    &__headline {
        font-family: $font-family-univers-condensed-bold;
        text-transform: uppercase;
        color: $color-alizarin;
        font-size: px-to-em(40px);
        line-height: 1;
        margin-bottom: .25em;
        position: relative;
        z-index: 1;
        
    }

    &__brief {
        @include font-secondary();

        font-size: px-to-em(20px);
        line-height: 1.2;
        position: relative;
        z-index: 1;

        &--mobile {
            display: block;
        }

        &--desktop {
            display: none;
        }
    }

    &__detail-content {
        display: block;
        position: relative;
        z-index: 1;
        flex: 1 1 auto;

        // &::after {
        //     content: '';
        //     background-color: $color-white;
        //     height: 0%;
        //     width: 100%;
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        // }
    }

    &__person-details {
        padding: 1em 1.5em;
        line-height: 1.2;
        text-transform: uppercase;
        position: relative;
        z-index: 1;
    }
    &__person-title {
        font-family: $font-family-univers-condensed-bold;
    }

    &__call-to-action {
        background-color: $color-alizarin;
        color: $color-white;
        font-size: 1em;
        font-family: $font-family-univers-condensed-bold;
        text-transform: uppercase;
        line-height: 1;
        text-align: left;
        width: 100%;
        border: 0;
        
        &-label {
            // @include ico-icon-after($icon-chevron-right-thin) {
            //     padding-left: 0.2em;
            //     position: absolute;
            // }       
            display: block; 
            padding: 0.75em;
        }

    }

    &__call-to-action,
    &__connections {
        width: 100%;
        align-self: flex-end;
    }

    &__connections {
        display: flex;        
    }

    &__connection {
        width: 25%;
        height: 100%;
        padding-bottom: 20%;
        flex-shrink: 0;
        background: no-repeat center;
        background-size: cover;
        position: relative;

        &--spacer {
            width: auto;
            flex-grow: 1;
        }

    }

    &__scroll-down {
        display: none;
    }

    &__panel {
        &:focus {
            outline: thin dotted;
        }
    }

    &__animation {
        &-connection {
            transition: none;
        }
    }

    @include min-width-between {
        &__header {
            position: static;
        }

        &__tablet-header {
            position: relative;
            // padding-left: calc(#{$mobile-image-width} + 2em);
        }

        &__person-details {
            padding-left: 0;
        }

        &__profile-photo {
            height: $mobile-image-height;
        }

    }
    @include min-width-sm {
        $vertical-spacing: 30px;
        $mobile-image-width: 46%;
        position: sticky;
        top: px-to-em($site-header-height);

        &__header {
            padding-left: calc(#{$mobile-image-width} + 2em);
            padding-right: px-to-em(24px);
            padding-top: px-to-em(30px);
            padding-bottom: px-to-em(23px);
        }

        &__profile-photo {
            width: $mobile-image-width;
            height: 100%;
        }
        
        &__body {
            padding-top: px-to-em(45px);
        }
        &__headline {
            font-size: calc(60px + (88 - 60) * ((100vw - 770px) / (1600 - 770)));
            margin-bottom: 0;
        }

        &__brief {
            font-size: px-to-em(24px);
            margin-top: px-to-em($vertical-spacing, 24px);
        }
        &__person-details {
            margin-top: px-to-em($vertical-spacing);
        }

        &__scroll-down {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: rgba($color-black, 0.5);
            color: $color-white;
            text-transform: uppercase;
            height: px-to-em(60px);
            text-align: center;
            padding-bottom: 10px;
            z-index: 1;

            &:after,
            &:before {
                content: '';
                width: 35px;
                border-top: thin solid $color-white;
                position: absolute;
                bottom: 15px;
                left: 50%;
                transform: translateX(-50%);
            }

            &:before {
                transform: translateX(-100%) rotate(15deg);
            }
            &:after {
                transform: rotate(-15deg);
            }
        }
    }
    
    @include min-width-md {
        $mobile-image-width: 50%;
        $connections-height: 180px;
        $content-top-padding: clamp(24px,5vw,65px);
        $content-left-padding: clamp(24px,5vw,65px);
        $content-right-padding: 10%;
        $vertical-spacing: 30px;
        
        min-height: calc(100vh - #{px-to-em($site-header-height)});

        &__body {
            --shade-height: 100%;

            padding-left: $mobile-image-width;
            padding-right: 0;
            padding-bottom: $connections-height;
            flex-direction: column;
            // min-height: inherit;
            height: calc(100vh - #{px-to-em($site-header-height)});

            &::before {
                content: '';
                background-color: $color-alizarin; 
                position: absolute;
                left: 0;
                top: 0;
                width: 50%;
                height: var(--shade-height);
                z-index: 2;
            }

            &::after {
                content: '';
                background-color: $color-alizarin; 
                position: absolute;
                right: 0;
                bottom: 0;
                width: 50%;
                height: var(--shade-height);
                z-index: 2;
            }
        }

        &__header {
            flex-shrink: 0;
            position: static;
            padding: 0;
            // padding: $content-padding;
            // padding-top: px-to-em($vertical-spacing);
        }

        &__tablet-header {
            position: static;
            z-index: 1;
            padding: 0;
            $connections-height: 20vh;
            min-height: calc(100vh - #{px-to-em(45px)} - #{$connections-height} - #{$site-header-height}); 
            display: flex;
            flex-direction: column;
            align-items: stretch;

        }

        &__detail-content {
            &::after {
                // animation: detailTextEnter $spf $animationCurve forwards calc(#{$spf} * 3);
            }
        }

        &__profile-photo {
            width: $mobile-image-width;
        }

        &__person-details {
            padding-left: $content-left-padding;
            padding-right: $content-right-padding;
            margin-top: px-to-em($vertical-spacing);
            flex-grow: 1;
            display: flex;
            align-items: flex-end;
            align-content: flex-end;
            flex-wrap: wrap;
            padding-bottom: 1em;
            opacity: 0;
            transform: translateY(1em);
            // animation: briefEnter $animationCurve $spf forwards calc(#{$spf} * 4);
        }
        &__person-title,
        &__person-subtitle {
            width: 100%;
            opacity: 0;
            transform: translateY(1em);
            display: block;
        }

        &__person-subtitle {
            font-family: $font-family-univers-condensed;
            display: inline-block;
            font-size: 90%;
        }

        &__brief {
            font-size: clamp(20px, 2vw ,24px);
            margin-top: 0;
            max-width: 100%;
            opacity: 0; 
            padding-left: $content-left-padding;
            padding-top: 30px;
            padding-right: $content-right-padding;
            transform: translateY(.5em);
            // animation: briefEnter $animationCurve $spf forwards calc(#{$spf} * 3);

            &--desktop {
                display: block;
            }

            &--mobile {
                display: none;
            }
        }
        &__connections {
            height: $connections-height;
            position: absolute;
            z-index: 1;
            right: 0;
            bottom: 0;
            width: 100% - $mobile-image-width;
        }

        &__connection {
            opacity: 0;
        }

        $connections-height: 20vh;
        $connections-height-max: 215px;

        &__call-to-action {
            position: absolute;
            right: 100% - $mobile-image-width;
            bottom: 0;
            width: $connections-height-max;
            height: $connections-height;
            font-size: px-to-em(20px);
            display: inline-flex;
            opacity: 0;
            
            &-label {
                padding: 1.25em;
                background-color: $color-alizarin;
                // color: transparent;
                position: absolute;
                bottom: 0;
                right: 0;
                height: $connections-height;
                $connections-height-max: 215px;
                $connections-height-max: 215px;
                opacity: 1;
            }
        }

        &__headline {
            $horizontal-spacing: 5%;
            $vertical-spacing: 30px;
            // transform: translateY(-150%);
            font-size: clamp(60px, 6vw ,85px);
            padding-left: $content-left-padding;
            padding-top: $content-top-padding;
            padding-right: $content-right-padding;
            opacity: 0;
            transform: translateY(.5em);
          
        }

        &__header,
        &__person-details {
            max-width: 700px;
        }

        &__body {
            padding-bottom: $connections-height;
        }

        &__connections {
            height: $connections-height;
            max-height: $connections-height-max;
        }

        &__call-to-action {
            // width: $connections-height-max;
            // height: $connections-height;
            // max-height: $connections-height-max;
            font-size: px-to-em(22px);
            max-height: none;
            // height: 100%;
            // width: 50%;
        }
    }

    @include min-width-lg {
        &__brief {
            padding-right: 10%;
        }
    }

    @include ipadpro-portrait {
        // min-height: inherit;

        // &__tablet-header {
        //     // position: relative;
        //     padding-left: calc(#{$mobile-image-width});
        // }
        // &__header,
        // &__profile-photo {
        //     padding-left: 0;
        // }

        // &__body {
        //     padding-left: 0;
        //     padding-bottom: 0;
        // }

        // &__call-to-action {
        //     position: relative;
        //     height: auto;
        //     width: 100%;
        //     left: 0;
        //     animation: none;
        //     &-label {

        //     }
        // }

        // &__connections {
        //     position: relative;
        //     width: 100%;
        //     right: auto;
        //     bottom: auto;
        // }
        // &__connection {
        //     width: 25%;
        //     height: 100%;
        //     padding-bottom: 25%;
        // }
    }

    @include careers-short-height-only {
        &__headline {
            // font-size: px-to-em(66px);
            font-size: calc(2.5em + 2vh);
        }
        &__brief {
            font-size: px-to-em(20px);
            max-width: px-to-em(580px, 20px);
        }

        &__person-details {
            margin-top: 1vh;
        }
    }
}