// BASE LINE HEIGHT
$base-line-height: 1.4;
$measure: 40em;

html {
  font-size: $font-size;
}
// body {
//     max-width: 100%;
//     margin: auto;
//     line-height: 1.4;

// }
a {
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0 0 1em;
}
// HEADERS
h1,
.h1 {
  @include font-size($font-size-h1);
}
h2,
.h2 {
  @include font-size($font-size-h2);
}
h3,
.h3 {
  @include font-size($font-size-h3);
}
h4,
.h4 {
  @include font-size($font-size-h4);
}
h5,
.h5,
h6,
.h6 {
  @include font-size($font-size-h5);
  margin-bottom: 0.5em;
}
