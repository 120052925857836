.prominent-message {
    background-color: $color-black;
    text-align: center;
    padding: px-to-em(50px) px-to-em($spacing * 2);
    color: $color-white;
    position: relative;
    z-index: 2;
    transform: translateZ(0);


    &__author {
        font-family: $font-family-univers-condensed-bold;
        text-transform: uppercase;

        &:before {
            content: '— ';
        }
    }

    &__title {
        font-family: $font-family-univers-condensed-light;
        text-transform: uppercase;
        line-height: 1.1;
    }
    
    &__cite {
        display: block;
        margin-top: px-to-em(10px);
        font-style: normal;
    }

    &__quote {
        @include font-secondary();
        font-size: px-to-em(20px);
        font-weight: 700;
        line-height: 1.2;

        &:before {
            content: '“';
        }

        &:after {
            content: '”';
        }
    }

    &__quote,
    &__cite {
        opacity: 0;
        transition: opacity 0.9s ease-in;
    }

    &__cite {
        transition-delay: 0.2s;
    }

    &__fadein--animate & {
        &__quote,
        &__cite {
            opacity: 1;
        }
    }

    &__blockquote {
        max-width: px-to-em(1078px);
        margin-left: auto;
        margin-right: auto;
    }

    @include min-width-md {
        // height: calc(100vh - #{$site-header-height});
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        padding-top: px-to-em(120px);
        padding-bottom: px-to-em(120px);

        &__blockquote {
            width: 100%;
        }

        &__author,
        &__title {
            font-size: px-to-em(24px);
        }

        &__quote {
            // font-size: px-to-em(48px);
            font-size: calc(35px + (6) * ((100vw - 770px) / (1600 - 770)));
        }
        
        &__cite {
            margin-top: px-to-em(20px);
        }
    }
}