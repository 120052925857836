.nav-bar {
}

.nav-bar--horizontal {
  @include clearfix;

  padding: 1em;
}

.nav-bar__logo {
  display: inline-block;
  margin: 0;
}

.nav-bar__nav {
  float: right;

  .list__item {
    line-height: 2;
  }
}
