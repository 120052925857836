@use "sass:math";

@mixin clearfix {
  &:after {
    clear: both;
  }

  &:before,
  &:after {
    display: table;
    content: " ";
  }
}

@mixin interactive() {
  cursor: pointer;
}
.interactive {
  @include interactive();
}

@mixin center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin borderBox {
  box-sizing: border-box;
}

@mixin display($display: block) {
  display: $display;

  @if ($display == inline-block and $legacy_support_for_ie) {
    *display: inline;
    zoom: 1;
  }
}

// arguments in pixels i.e. (12px, 11px)
@function px-to-em($px, $base: $font-size) {
  @return math.div($px, $base) * 1em;
}
// arguments in pixels i.e. (12px, 11px)
@function px-to-rem($px, $base: $font-size) {
  @return math.div($px, $base) * 1rem;
}

@mixin font-size($fs: $font-size) {
  font-size: px-to-rem($fs);
}

/// Screen Width Breakpoints:  Insipred by BOOTSTRAP CSS
/// @param {Number} $width - Width
/// @param {Number} $height ($width) - Height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin max-width-xs() {
  @media screen and (max-width: $screen-xs) {
    @content;
  }
}

@mixin max-width-sm() {
  @media screen and (max-width: $screen-sm) {
    @content;
  }
}

@mixin min-width-sm() {
  @media screen and (min-width: $screen-sm) {
    @content;
  }
}

@mixin min-width-between() {
  @media screen and (min-width: $screen-between) {
    @content;
  }
}


@mixin max-width-between() {
  @media screen and (max-width: $screen-between) {
    @content;
  }
}

@mixin max-width-md() {
  @media screen and (max-width: $screen-md) {
    @content;
  }
}

@mixin min-width-md() {
  @media screen and (min-width: $screen-md) {
    @content;
  }
}

@mixin max-width-lg() {
  @media screen and (max-width: $screen-lg) {
    @content;
  }
}

@mixin min-width-lg() {
  @media screen and (min-width: $screen-lg) {
    @content;
  }
}

@mixin max-width-xl() {
  @media screen and (max-width: $screen-xl) {
    @content;
  }
}


@mixin min-width-xl() {
  @media screen and (min-width: $screen-xl) {
    @content;
  }
}

@mixin careers-tablet-only() {
  //@include max-width-md() {
  @media screen and (max-width: $screen-md) and (min-width: $screen-sm), (orientation: portrait) {
    @content;
  }
}

@mixin careers-mobile-only() {
  //@include max-width-md() {
  @media screen and (max-width: $screen-md), (orientation: portrait) {
    @content;
  }
}

@mixin careers-desktop-only {
  // @include min-width-md() {
  @media screen and (min-width: $screen-md) and (orientation: landscape) {
    @content;
  }
}

@mixin ipadpro-portrait() {
  @media only screen 
    and (min-width: 1024px) 
    and (max-height: 1366px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1.5) {
      @content;
  }
}

@mixin when-ios-scroll-fix {
  @supports (-webkit-overflow-scrolling: touch) {
    @content;
  }
}

//for tablets in landscape and short but wide screens
@mixin careers-short-height-only {
  @media screen 
    and (min-width: $screen-md) 
    and (max-height: $screen-height-sm) 
    and (orientation: landscape) {
    @content;
  }
}

// Prefixes and Transitions -- BOOTSTRAP CSS
@mixin animation($animation) {
  -webkit-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

@mixin animation-name($name) {
  -webkit-animation-name: $name;
  -o-animation-name: $name;
  animation-name: $name;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -ms-transform: rotate($degrees); // IE9 only
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin translate3DFix() {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); // IE9 only
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -ms-transform: translate($x, $y); // IE9 only
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin transition($transition...) {
  -webkit-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}
@mixin transition-property($transition-property...) {
  -webkit-transition-property: $transition-property;
  transition-property: $transition-property;
}
@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
  transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration...) {
  -webkit-transition-duration: $transition-duration;
  transition-duration: $transition-duration;
}
@mixin transition-timing-function($timing-function) {
  -webkit-transition-timing-function: $timing-function;
  transition-timing-function: $timing-function;
}
@mixin transition-transform($transition...) {
  -webkit-transition: -webkit-transform $transition;
  -moz-transition: -moz-transform $transition;
  -o-transition: -o-transform $transition;
  transition: transform $transition;
}
