//DEFAULT GLOBAL HTML STYLE UPDATES
$a-tags: "a, a:active, a:hover";
$a-tags-hover: "a:active, a:hover";

html,
body {
  height: 100%;
  background-color: $background-color;
}
div,
header,
footer {
  box-sizing: border-box;
}

p {
  margin-bottom: $spacing;
  margin-top: 0;
}

img {
  max-width: 100%;
}

#{$a-tags} {
  color: $color-link;
  text-decoration: none;
}

#{$a-tags-hover} {
  color: $color-link-alt;
}
