@use "sass:math";

$mobile-trigger-height: 80px;

.lawyer-careers-navigation {
  &.float-side {
    @include translate(-110%, 0);
    @include transition(transform 0.2s ease-in);
    z-index: 12;
    .nav-items {
      background-color: $color-black;
      padding: 0;
    }

    .nav-item {
      display: block;
      position: relative;
      @include font-size($font-size-md);

      &:hover,
      &.active {
        a {
          border-right-color: $color-brand-one;
        }
      }

      //match height of last item with mobile nav icon
      &:last-child {
        // min-height: $mobile-trigger-height;
        .itemtitle {
          min-height: $mobile-trigger-height - $spacing * 2;
          color: $color-brand-one;
        }
        .nav-item-inner {
          padding-bottom: math.div($spacing, 2);
        }
      }
    }

    .nav-item-inner {
      padding: math.div($spacing, 2) math.div($spacing, 2) 0 $spacing;
      border-right: math.div($spacing, 4) solid;
      border-right-color: transparent;
      @include transition(border 0.2s ease-in);
      // max-width: 165px;
    }

    .pretitle {
      font-size: 0.8em;
    }
    .itemtitle {
      font-weight: bold;
      // width: 80%;
      font-family: $font-family-secondary-name;
      // display: flex;
      // align-items: center;

      display: block;
      max-width: 100px;
      word-break: keep-all;
    }

    .side-nav-active &,
    &.active {
      @include translate(0, 0);

      // @include careers-mobile-only {
      //   @include translate(-100%, 0);
      // }

      @media only screen and (max-width: 1600px) {
        @include translate(-100%, 0);
      }

      //don't push it over as far since it fails to go over the spacing
      @include careers-short-height-only {
        @include translate(-100%, 0);

        @include when-ios-scroll-fix {
          @include translate(calc(-100% + #{$spacing}), 0);
          bottom: $spacing;
        }
      }
    }

    &.active-mobile {
      @include careers-mobile-only {
        @include translate(0, 0);
      }
      //also active on stubby landscape devices
      @include careers-short-height-only {
        @include translate(0, 0);
      }

      @media only screen and (max-width: 1600px) {
        @include translate(0, 0);
      }

    }
    
  }
}

.apply-ie-fix .lawyer-careers-navigation.float-side .itemtitle {
  display: block;
  margin-right: 20px;
}

.mobile-side-nav-trigger {
  position: absolute;
  bottom: 0;
  left: 100%;
  width: 35px;
  height: $mobile-trigger-height;
  background-color: $color-grey-two;
  opacity: 0;
  @include transition(opacity, 0.7s);

  @include ico-icon-before($icon-chevron-right-thin);
  &::before {
    color: $color-brand-light;
    font-size: 24px;
    line-height: $mobile-trigger-height;
    width: 100%;
    text-align: center;
  }
}

.lawyer-careers-navigation.active {
  .mobile-side-nav-trigger {
    opacity: 0;

    @media only screen and (max-width: 1600px) {
      opacity: 1;
    }
  }
}

.lawyer-careers-navigation.active-mobile {
  .mobile-side-nav-trigger {
    &::before {
      transform: rotate(180deg);
    }
  }
}
