.site-footer {
  position: relative;
  width: 100%;
  z-index: 2;
  padding: 0;

  background-color: $color-brand-pop;
  color: $color-brand-light;

  .title {
    color: $color-footer-title;
  }

  .content-wrapper {
    padding: ($spacing * 2) 2.5em 0 2.5em;
    margin: 0 auto;
  }

  a {
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }

  .para-container & {
    height: 0;
    overflow: hidden;
  }

  @include max-width-md {
    padding: ($spacing) 0;
  }
}

.site-footer-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.site-footer-menu-link {
  text-transform: uppercase;
  // font-weight: bold;
  font-size: 1.125em;
  display: inline-block;
  line-height: 1.2;
  margin-right: 0.25em;
}
.site-footer-utility-link {
  // font-weight: bold;
  text-transform: uppercase;
  padding-right: 2.5em;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.5em;
  line-height: 1.2;
  vertical-align: bottom;
}

.site-footer-additional {
  padding-top: 1.5625em;
  padding-bottom: 0;
  margin-top: $spacing * 1.5;
  border-top: 1px solid $color-brand-light;
  overflow: hidden;
}

.site-footer .social-links-icon {
  font-size: 1.625em;
  margin-left: 0.5em;
}

@media only screen and (max-width: 850px) {
  .site-footer-menu {
    display: block;

    &-item {
      flex: 1 1 100%;
    }
  }

  .site-footer-utility-item {
    margin-right: 0;
  }

  .site-footer-utility-link {
    padding-right: 0;
  }
}

