$careers-facts-width: 550px;

$timing-fadein-letters: 0.75s;

// @keyframes color-fill {
// 	0: {fill: transparent}
// 	25%: {fill: #777777}
// 	50%: {fill: rgba(27,27,27,.98)}
// 	100%: {fill: #000}
// }

.slogan-animation {
  background-image: radial-gradient(
    circle at top left,
    #474747,
    rgba(27, 27, 27, 0.98) 70%
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-right: px-to-em($careers-facts-width) + px-to-em($spacing * 7.5);
  padding-top: px-to-em($spacing * 3);
  padding-bottom: px-to-em($spacing * 2);
  padding-left: px-to-em($spacing * 2);
  transition: opacity 0.5s ease-in 1s;
  text-align: center;

  @include careers-mobile-only {
    padding-right: 3%;
    padding-left: 3%;
    padding-top: px-to-em($spacing);

    &.animation-complete {
      opacity: 0.1;
    }
  }

  @media screen and (max-height: px-to-em(790px)) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.svg-inline {
  fill: rgba(0, 0, 0, 0.01);
  transition: fill 10s linear;

  &.animation-done {
    fill: rgba(0, 0, 0, 1);
  }
}

.responsive-svg-container {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;

  @media screen and (min-height: px-to-em(790px)) {
    height: px-to-em(575px);
  }

  @include careers-mobile-only {
    height: 100%;
  }
}

//container handles maximum proportions
.responsive-svg-canvas {
  max-width: px-to-em(585px);
  width: 100%;
}

//responsive container that handles dimensions/sizing of SVG in a cross-browser consistent way;
.responsive-svg {
  //position: absolute;//needed to center text in safari
  position: relative;
  height: 0;
  padding-top: 81.275%;

  @include careers-mobile-only {
    max-width: px-to-em(700px);
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;

    //temporary fix
    // height: 100%;
    // width: 100%;
  }

  #letter-fill {
    fill: #191919;

    @include careers-mobile-only {
      fill: #000;
    }

    g {
      opacity: 0;
      transition: opacity $timing-fadein-letters ease-in-out 0.5s;
    }
  }

  #letter-outlines {
    .animation-done {
      opacity: 1;
    }
  }

  #letter-fill {
    .animation-done {
      opacity: 1;
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

$list-default-timing: 350ms;

$list-default-timing-delay: 200ms;

.pop-content-list-item {
  .item-inner {
    opacity: 0;
    transition: opacity $list-default-timing ease-in;
  }

  &:nth-child(2) {
    .item-inner {
      transition-delay: $list-default-timing-delay;
    }
  }
  &:nth-child(3) {
    .item-inner {
      transition-delay: $list-default-timing-delay * 2;
    }
  }
  &:nth-child(4) {
    .item-inner {
      transition-delay: $list-default-timing-delay * 3;
    }
  }
  &:nth-child(5) {
    .item-inner {
      transition-delay: $list-default-timing-delay * 4;
    }
  }

  .animation-listing & {
    .item-inner {
      opacity: 1;
    }
  }

  @media screen and (max-width: px-to-em($screen-md)) {
    $mobile-transition-delay: 1s;

    .item-inner {
      transition: opacity $list-default-timing ease-in $mobile-transition-delay;
    }

    &:nth-child(2) {
      .item-inner {
        transition: opacity $list-default-timing ease-in
          ($mobile-transition-delay + $list-default-timing-delay);
      }
    }
    &:nth-child(3) {
      .item-inner {
        transition: opacity $list-default-timing ease-in
          ($mobile-transition-delay + $list-default-timing-delay * 2);
      }
    }
    &:nth-child(4) {
      .item-inner {
        transition: opacity $list-default-timing ease-in
          ($mobile-transition-delay + $list-default-timing-delay * 3);
      }
    }
    &:nth-child(5) {
      .item-inner {
        transition: opacity $list-default-timing ease-in
          ($mobile-transition-delay + $list-default-timing-delay * 4);
      }
    }
  }
}
