@use "sass:math";

.icon {
  // @extend .fa;
  margin: 0 0.5em 0 0;
  // font-family: "skadden";
  // font-style: normal;
  // &.message-icon-alert {
  // 		@extend .fa-bell-o;
  // }
  // &.message-icon-message {
  // 	@extend .fa-envelope;
  // }
  // &.publication {
  // 	@extend .fa-book;
  // }
  // &.news {
  // 	@extend .fa-newspaper-o;
  // }
  // &.event {
  // 	@extend .fa-calendar;
  // }
  // &.blog {
  // 	@extend .fa-pencil;
  // }
  // &.search {
  // 	@extend .fa-search;
  // }
  // &.more {
  // 	@extend .fa-angle-right;
  // }
  // &.icon-after {
  // 	margin: 0 0 0 .5em;
  // }

  .close-icon {
    // @include ico-icon-before($icon-close)
  }

  &.search,
  &.menu,
  &.close {
    margin: 0 auto;
    width: 1em;
    height: 1em;
    position: relative;
    display: inline-block;
    overflow: hidden;
  }

  &.menu {
    &::before {
      content: "";
      position: absolute;
      top: 0.25em;
      left: 0;
      width: 1em;
      height: 0.125em;
      border-top: 0.375em double;
      border-bottom: 0.125em solid;
      border-color: inherit;
    }
  }

  &.close {
    width: 25px;
    height: 25px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: #000;
    }

    &::before {
      @include rotate(45deg);
    }
    &::after {
      @include rotate(-45deg);
    }
  }
}

.icon-block {
  position: relative;
  display: inline-block;

  .label {
    display: none;
  }
}

@mixin custom-arrow($color: #fff, $size: 28px, $thickness: 3px) {
  display: inline-block;
  position: relative;
  width: $size;
  height: $thickness;
  background: $color;
  top: -(math.div($size, 3));

  &:before {
    position: absolute;
    top: -(math.div($size, 4)); //7
    right: 0;
    content: " ";
    width: math.div($size, 2);
    height: math.div($size, 2);
    border: $thickness solid $color;
    border-left: 0;
    border-bottom: 0;
    @include rotate(45deg);
  }
}

.icon-custom-arrow-right {
  @include custom-arrow($color-brand-light, 28px, 3px);
}
