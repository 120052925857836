$transition-rate: 0.5s;
$transition-delay: 0.6s;

.transition {
  @include transition(opacity $transition-rate ease-in);
  @include transition-delay(0);

  .para-panel.active & {
    opacity: 1;
  }

  &.transition-fadein-1,
  &.transition-fadein {
    opacity: 0;
    @include transition-delay($transition-delay);
  }

  &.transition-fadein-2 {
    opacity: 0;
    @include transition-delay($transition-delay * 2);
  }
  &.transition-fadein-3 {
    opacity: 0;
    @include transition-delay($transition-delay * 3);
  }
  &.transition-fadein-4 {
    opacity: 0;
    @include transition-delay($transition-delay * 4);
  }
  &.transition-fadein-5 {
    opacity: 0;
    @include transition-delay($transition-delay * 5);
  }
  &.transition-fadein-6 {
    opacity: 0;
    @include transition-delay($transition-delay * 6);
  }
  &.transition-fadein-7 {
    opacity: 0;
    @include transition-delay($transition-delay * 7);
  }
}

.slide-in-content {
  @include translate(0, 30px);
}
