@use "sass:math";

@import "../fonts/variables";

@import "../fonts/icons";

@import "../fonts/mixins";

//$ENABLE_DIM if .has-active-interaction does anything

$point-size: 50px;
$pulse-scale: 1.04;
$pulse-midpoint: 1.03;

// to avoid glitchy transition conflicts, set the entrance transition on the image and the hover on the parent element
.active-interaction-panel .desktop-interaction-image {
  animation: interaction-pulse-from-zero 1.5s 2;
  animation-fill-mode: forwards;
}

.apply-ie-fix .active-interaction-panel .desktop-interaction-image {
  animation: interaction-pulse-from-zero 1.5s 1;
}

.desktop-interaction {
  position: absolute;
  //x and y are set from sitecore inline
  pointer-events: auto;
  width: $point-size;
  height: auto;
  @include transition(transform 0.3s ease-out);

  &:not(.active):hover {
    transform: scale($pulse-scale) !important;
  }
  //set just the iteration count to avoid glitches
  &.active .desktop-interaction-image {
    animation-iteration-count: infinite;
  }
}

.apply-ie-fix .desktop-interaction.active .desktop-interaction-image {
  animation: interaction-pulse-from-zero-for-ie 1.5s infinite;
}

.scroll-through .desktop-interaction {
  pointer-events: none !important;
}

.desktop-interaction-image {
  transform: translateZ(0);
  height: 100%;
  width: 100%;
  transition: filter 0.3s ease;
  //remove focus outline in this case, as sidebar provides accessible content
  outline: none;
}

@if ($ENABLE_DIM) {
  @include careers-desktop-only() {
    .has-active-interaction
      .desktop-interaction:not(.active)
      .desktop-interaction-image {
      filter: brightness(50%);
      //IE can't filter
      .apply-ie-fix & {
        opacity: 0.6;
      }
    }
    //  .dark-text .has-active-interaction .desktop-interaction:not(.active) .desktop-interaction-image {
    //     opacity: .3;
    // }
    //override the black background image so it actually fades to white
    // .dim-white-override {
    //     background-color: $color-white;
    // }
  }
}

//the plus signs on the desktop - if no image
.desktop-interaction-icon {
  color: $color-grey-zero;
  font-size: $point-size;
}

.light-text .desktop-interaction-icon {
  color: $color-brand-light;
}

.desktop-interaction-list {
  float: none;
  width: 100%;
  box-sizing: border-box;
}

.mobile-desktop-image-img {
  width: 100%;
  height: auto;
}

.above-desktop-interactions {
  z-index: 11 !important;
}

//same dimensions as absolute-bio-background for consistency
.desktop-interaction-fixed-wrapper {
  z-index: 10;
  pointer-events: none;
  position: fixed;
  visibility: hidden;
  width: calc(100vw - #{$spacing * 2});
  height: 100%;
  left: $spacing;
  bottom: 0;
  box-sizing: border-box;
  //   @include min-width-sm() {
  //     padding: $spacing;
  //   }
}

//percentage padding is based on the width parent element
// so fix the width of this parent to keep aspect ratio
.desktop-interaction-max-width {
  //fallback for no calc
  max-width: math.div(100vh, $desktop-aspect-ratio);
  max-width: calc(
    #{math.div(100vh, $desktop-aspect-ratio)} - #{math.div(
        125px,
        $desktop-aspect-ratio
      )}
  );
}

.desktop-interaction-fixed-ratio {
  //coordinate grids are anchored to this element
  //fixed aspect ratio, contained by its parent
  height: 0;
  position: absolute;
  bottom: 20px;
  width: 100%;
  // padding-top: 60%;
  padding-top: 100% * $desktop-aspect-ratio;
}

.desktop-anchor-top .desktop-interaction-fixed-ratio {
  // top:0;
  top: $header-height + $float-nav-height;
}

.active-interaction-panel .desktop-interaction-fixed-wrapper {
  visibility: visible;
}

.desktop-interaction-list-wrapper {
  background-color: $color-brand-dark;
  padding: 0 1em 0.1em 1em;
}

//The New Style: always dark, dark background
.desktop-interaction-list-item {
  color: $color-white;

  .pretitle {
    margin-bottom: 0;
    //make room for absolute arrows
    margin-right: 50px;
  }
}

.panel-content-list .desktop-interaction-list-header {
  border-top: none;

  .header {
    text-transform: uppercase;
    padding: 0.5em 0 0 0;
    color: $color-grey-header;
    display: block;
    font-size: $font-size-accessible-bold;
  }
}

.desktop-interaction-content {
  @include transition(max-height 0.3s ease-out);
  max-height: 0em;
  overflow: hidden;
}

.desktop-interaction-content-padding {
  padding-top: 0.5em;
}

.desktop-interaction-expanded {
  .desktop-interaction-content {
    //workaround to to animate dynamic height
    max-height: 20em;
  }

  .desktop-interaction-list-icon {
    transform: rotate(-180deg);
  }
}

@if ($ENABLE_DIM) {
  @include careers-desktop-only() {
    .has-active-interaction
      .panel-content-list-item:not(.desktop-interaction-expanded):not(.desktop-interaction-list-header) {
      opacity: 0.4;
    }
  }
}

.desktop-interaction-list-icon {
  position: absolute;
  color: $color-alizarin;
  justify-content: flex-start;
  // margin-top: 5px;
  right: 10px;
  font-size: 24px;
  @include transition(transform 0.2s ease-out);
  @include ico-icon-before($icon-chevron-down-thin);
}

//numbers indicating the interactive images
// .desktop-interaction-number {
//     position: absolute;
//     color: $color-grey-zero;
//     justify-content: flex-start;
//     left: 80%;
//     bottom: 80%;
//     font-weight: bold;
//     font-size: 24px;
//     z-index: 1;
//     @extend .font-family-primary;
//     transition: none;
// }

//eliminate inline transition delay on the way out so it comes right back
// .desktop-interaction-logic-wrapper:not(.active-interaction-panel) .desktop-interaction-number {
//     transition-delay: 0s !important;
// }

// .light-text .desktop-interaction-number {
//     color: $color-brand-light;
// }

@keyframes interaction-pulse-from-zero {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale($pulse-midpoint);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes interaction-pulse-from-zero-for-ie {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale($pulse-midpoint);
  }

  100% {
    transform: scale(1);
  }
}

@if ($ENABLE_DIM) {
  .active-dim-important {
    @include careers-desktop-only {
      opacity: 0.5 !important;
    }
  }
}
