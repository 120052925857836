@use "sass:math";

@mixin paraBackground() {
  background-color: transparent;

  .dark & {
    background-color: $color-grey-zero;
  }

  .light & {
    background-color: $color-brand-light;
  }
}
//must be on out most container //typically .main or the page wrapper
.main-para,
.para-container,
.para-bg-fixer,
.para-bg-fixer-content,
.para-panel-background {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.para-bg-fixer,
.para-container {
  overflow: auto;
  background-color: $color-black;

  //mobile only
  //padding-top: $header-height;

  @include min-width-sm() {
    /*padding: $spacing;
    padding-top: $header-height + $spacing;*/

    .stick-float-nav & {
      padding-right: 0;
    }
  }

  //remove padding for iOS devices so the navigation isn't cut off
  //because the kinetic scroll fix messes up the stacking context in ways difficlt to comprehend
  @include when-ios-scroll-fix {
    @include careers-mobile-only {
      padding: $header-height 0 0;
    }

    //remove padding because bizarre stacking context blocks the nav
    @include careers-short-height-only {
      padding-top: 0;
    }
  }
}

.para-container {
  z-index: 0;
}
.para-bg-fixer {
  z-index: 1;
  position: absolute;
  overflow: hidden;
  background-color: transparent;
}

.para-bg-fixer-content {
  // background-image: url('/../../../../media/images/home/background-desktop.jpg');

  .video-background {
    opacity: 1;
    @include transition(opacity 0.6s ease);
    //video looks weird when fading out
    &.has-video {
      transition: none;
    }
  }
}

.close-video {
  .para-bg-fixer-content {
    .video-background {
      opacity: 0;
    }
  }
}

.absolute-bio-background {
  position: absolute;
  background-origin: content-box;
  box-sizing: border-box;
  @include min-width-sm() {
    padding: $spacing;
    padding-top: $header-height + $float-nav-height;
  }
  background-clip: content-box;
}

.absolute-bio-desktop {
  background-size: contain;
  position: absolute;
  left: 0;
  background-repeat: no-repeat;

  &.desktop-anchor-bottom {
    background-position: left bottom;
    bottom: 0;
  }

  &.desktop-anchor-top {
    background-position: left top;
    top: 0;
  }
}

//contains all panels
.para-panels {
  position: relative;
  width: 100%;
  padding: 0;

  overflow: -moz-scrollbars-none;
  height: 100%; //for parallax fx
  overflow: hidden; //for parallax fx
  -ms-overflow-y: auto;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0 !important;
  }
  -ms-overflow-style: none;
  //in theory this enables momentum on iOS but it breaks it weird
  -webkit-overflow-scrolling: touch;

  //this seems to negate the weird z-index artifacts-
  //webkit overflow scrolling introduces a stacking context that we need to account for
  //tablet has the problem of interactions floating above everything

  @include when-ios-scroll-fix {
    //ONLY Z index fixes issues on iphone (less fancy to worry about)
    z-index: 1;

    // @include careers-desktop-only  {
    //moved the desktop interactions to inside the scroll parent to solve stacking context problem
    //  -webkit-transform: translate3d(0,0,1px);
    //  z-index: 1;
    // }
  }
}

.para-panel {
  position: relative;
  left: 0;
  top: 0%;
  height: 100%; //for parallax fx
  width: 100%;
  z-index: 1;

  //the background image here is for mobile only
  @include careers-desktop-only() {
    background-image: none !important;
  }

  &#parallax-item-1 {
    overflow: hidden;
    //fix problem with pop-content shrinking now that this panel height is variable and 100% does nothing
    .para-panel-content {
      overflow: visible;
    }

    //make space for nav
    //padding-bottom: $float-nav-height;

    @include careers-mobile-only() {
      //keep height after singling out a fact
      //and keep the float nav out of the way
      min-height: 100vh;
    }
  }

  &#parallax-item-2 {
    height: auto;
    z-index: 5;
    overflow: hidden;
    height: 100%;
    .para-panel-content-inner {
      display: flex;
      align-items: center;
    }
  }

  &.full-size {
    min-height: 100%;
    height: auto;
  }

  &.para-panel-questionnaire {
    z-index: 12;
  }
}

@include careers-mobile-only() {
  .dark-background-mobile {
    background-color: $color-grey-zero;
  }

  .light-background-mobile {
    background-color: $color-brand-light;
  }
}

.para-panel-spacer {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.para-panel-inner {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: -6px; //to fix navigation scroll
  padding-top: 6px;

  @include paraBackground;
}

.para-panel-layer {
  @include translate3DFix();
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.para-panel-background {
  position: absolute;
  z-index: 1;
}

.para-panel-content {
  margin: 0;
  padding: 0;
  z-index: 2;
  min-width: 100%;
  max-width: 100%;
  overflow: hidden;
  opacity: 1;

  color: $color-brand-light;

  .light & {
    color: $color-brand-dark;
  }
}

.para-panel-content-inner {
  height: 100%;
  min-width: 100%;
  max-width: 100%;
}

.panel-vertical-center-title {
  display: table;
  position: relative;
  height: 100%;

  > h1 {
    display: table-cell;
    padding: 0;
    margin: 0;
    vertical-align: middle;
  }
}

.panel-featured-title {
  max-width: 55%;
  margin: 0 auto;
  // padding: 175px 0;

  > h1 {
    font-weight: bold;
    text-align: center;
    margin: 0;
    line-height: 1.2em;
    @include font-size($font-size-xxxxl);
    @extend .font-family-secondary;
  }
  em {
    font-style: normal;
    color: $color-brand-one;
    @include font-size($font-size-xxxxl);
    @extend .font-family-secondary;
  }

  @include max-width-lg() {
    max-width: 65%;
  }

  @include max-width-md() {
    max-width: 75%;
    padding: 100px 0;
  }

  @include max-width-sm() {
    max-width: 90%;
    padding: 6vh 0;
    > h1,
    em {
      //vw fallback
      font-size: 3.5rem;
      font-size: 7.5vw;
    }
  }
}

.panel-figure {
  // max-width: 600px;
  height: 100%;
  // height: calc(100vh - 105px);
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  z-index: 5;

  .image-responsive {
    height: 100%;
    box-sizing: border-box;
    position: relative;
  }

  .desktop {
    position: absolute;
    height: 100%;
    width: auto;
    bottom: 0;
    //override inline style for mobile
    @include min-width-sm {
      height: 100% !important;
    }
    @media (min-width: $screen-md) and (max-width: $screen-lg + 300) {
      $image-ratio: 1.4;
      max-width: math.div(90vh, $image-ratio);
      max-width: calc(#{math.div(100vh, $image-ratio)} - 110px);
    }
  }

  &.left-side {
    left: 0;
    bottom: 0;
    .desktop {
      left: 0;
      //override configurable inline style on mobile
      @include careers-mobile-only {
        left: 0 !important;
      }
    }
  }

  &.right-side {
    right: 0;
    bottom: 0;
    .desktop {
      right: 0;
      //override configurable inline style on mobile
      @include careers-mobile-only {
        right: 0 !important;
      }
    }
  }

  @include careers-mobile-only {
    padding-top: 150px;
  }
  // @include max-width-sm() {
  //   max-width: 75%;
  // }
}

.panel-jumbo-links {
  position: absolute;
  display: table;
  width: 100%;
  height: 35%;
  bottom: 0;
  left: 0;
}

.panel-bio-name {
  position: absolute;
  top: 0;
  width: 100%;
  height: 30%;
  padding: 4vh 30% 0 30%;
  text-align: center;
  @include careers-mobile-only {
    padding: 10px 0;
    padding-bottom: 10px;
    height: auto;
    // top: 10px;
    background-color: $color-brand-light;
    z-index: 1;
    // left: 0;
  }
  @include careers-short-height-only {
    padding-top: 5px;
  }
}

.panel-bio-name-content {
  display: inline-block;
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  // width: auto;
  padding: 0.1em 0.6em;
  @extend .font-family-primary;
  border: 3px solid $color-alizarin;
  border-radius: 3px;
  color: $color-grey-two;
  @include careers-mobile-only {
    border: none;
  }
  @include careers-short-height-only {
    font-size: 20px;
    border: none;
  }
}


