.hero {
  .over-content {
    bottom: 0;
  }

  @include max-width-md {
    max-width: 100%;

    &:not(.no-padding) {
      padding-left: $spacing;
      padding-right: $spacing;
    }
  }

  .content {
    .title {
      @include font-size($font-size-xxl);
      color: $color-brand-light;
    }
  }
}

.over-content {
  position: absolute;
  width: 100%;
  z-index: 100;
}

.hero-image {
  width: 100%;
  img {
    width: 100%;
    display: block;
  }
}
