.flyout {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  right: 0;
  height: 100%;
  background-color: $color-brand-dark;
  padding: 8% 5% 0;
  z-index: $z-index-nav-primary;
  max-width: 40%;

  &.site-search {
    background-color: $color-brand-light;
  }

  @include translate(102%, 0);

  @include transition(transform 0.2s linear);

  &.active {
    @include translate(0, 0);
  }

  @include max-width-md {
    max-width: 100%;
  }
}
