.video-background {
  position: absolute !important;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 0 !important;
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}
