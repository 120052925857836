.link-button-jumbo {
  position: relative;
  display: table-cell;
  width: 2%;
  border-top: 1px solid $color-grey-three;
  border-left: 1px solid $color-grey-three;
  background-color: $color-grey-zero;
  color: $color-brand-light;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  @include font-size($font-size-xl);

  .link-content {
    display: inline-block;
    vertical-align: middle;
  }

  .pretitle {
    display: block;
    @include font-size($font-size-sm);
    text-transform: uppercase;
    color: $color-brand-one;
    margin-bottom: 0.5em;
  }

  .icon {
    color: $color-brand-one;
    font-size: 3em;
    vertical-align: middle;
    @include font-size($font-size-jumbo);
  }

  &:first-child {
    border-left: 0;
  }

  &:hover {
    color: $color-brand-light;
  }
}
