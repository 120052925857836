// Extra small screen / phone
$screen-xs: 480px !default;
// For that awkward stage between phone / tablet
$screen-between: 670px !default;
// Small screen / tablet
$screen-sm: 768px !default;
// Medium screen / desktop
// also adjust in JS if you change it
$screen-md: 992px !default;
// Large screen / wide desktop
$screen-lg: 1200px !default;

$screen-xl: 1500px !default;

$screen-height-sm: 815px;

//SPACING FOR CALCULATIONS
$spacing: 20px;

// $header-height: 70px;
$header-height: 60px;
$header-height-mobile: $spacing * 3;
$float-nav-height: 45px;
$secondary-nav-height: $float-nav-height;

$footer-height: 225px;
$footer-height-mobile: auto;

$input-height: ($spacing * 2);

$desktop-aspect-ratio: 0.53;

//ICONS

//FONTS
$font-size-base: 16; //save for future calcs
$font-size: $font-size-base * 1px; //set html base font

//FONT SIZES: DESKTOP
$font-size-sm: 12px;
$font-size-md: 18px;
$font-size-accessible-bold: 20px;
$font-size-md-plus: 22px;
$font-size-lg: 24px;
$font-size-xl: 26px;
$font-size-xxl: 32px;
$font-size-xxxl: 36px;
$font-size-xxxl-plus: 45px;
$font-size-xxxxl: 56px;
$font-size-jumbo: 76px;
$font-size-mega: 102px;
$font-size-xmega: 130px;

$font-size-h1: $font-size-xxxl;
$font-size-h2: $font-size-xxl;
$font-size-h3: $font-size-xl;
$font-size-h4: $font-size-lg;
$font-size-h5: $font-size-md;

//Z INDEX ... this is out of date now

$z-index-main: 1;
$z-index-footer: 2;
$z-index-nav-primary: 3;
$z-index-header: 9;
$z-index-header-content: 10;
$z-index-float-nav: 13;

//BORDERS
$global-border: 1px solid $color-grey-four;
$form-border: 1px solid $color-grey-one;

//LEGACY
$legacy-support-for-ie: false !default;

//.has-active-interaction
$ENABLE_DIM: true;
