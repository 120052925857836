.questionnaire-question,
.question-inner {
  width: 100%;
  height: 100%;
}

.questionnaire {
  display: block;
  position: relative;
  width: 100%;
  height: calc(100vh - #{$site-header-height});
  background-color: $color-grey-zero;
  color: $color-white;

  .button-reset {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 100;
    margin-right: 40px;
    margin-bottom: 40px;
    font-weight: bold;
    &:focus {
      outline: 1px thin $color-grey-zero;
    }
    @include max-width-sm() {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
.questionnaire-results {
  position: absolute;
  // position: relative;
  width: auto;
  height: auto;
  left: 0;
  top: 10%;
  padding-left: $spacing*2;
  @include careers-short-height-only {
    padding-left: 0;
    max-width: 25%;
    top: 60px;
  }
  // background: $color-grey-zero;
  z-index: 1000;

  .questionnaire-results-item {
    @extend .font-family-secondary;
    font-size: 14px;
    font-weight: bold;
    &.inner {
      margin-left: 1em;
      margin-bottom: 20px;
    }

    @include careers-short-height-only {
      margin-bottom: 5px;

    }
    
  }
}

.questionnaire-heading {
    bottom: 50%;
}

.questionnaire-question {
  background-color: $color-grey-zero;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 0;
  visibility: hidden;
  @include transition(opacity .5s linear);

  .question-inner {
    display: flex;
    flex: 0 0 auto;
    align-items: left; //horizontal
    justify-content: center; //vertical
    flex-direction: column;
    @include careers-short-height-only {
      // justify-content: flex-end;
    }
  }

  &.question-started {
    z-index: 2;
    opacity: 1;
    visibility: visible;
  }

  .icon-custom-arrow-right {
    position: relative;
    margin-left: 0.5em;
    display: none;
    opacity: 0;
    left: 0;
    @include transition(all .07s linear);
  }

  //specific styles for question type


    .icon-custom-arrow-right {
      display: inline-block;
    }

    .questionnaire-step {
      &.completed {
        .icon-custom-arrow-right {
          opacity: 1;


          @keyframes bouncer {
            50% { left: 8px; }
          }
        }
      }
    }
}

//only animate when in view so iOS browsers don't spazz 
.questionnaire-step.completed .icon-custom-arrow-right {
    @include animation( bouncer 1s linear infinite );
}

.questionnaire-step {
  width: 50%;
  margin: 10px auto 30px;
  padding: 0;
  opacity: 0;
  height: 0;
  overflow: hidden;

  @include careers-short-height-only {
    margin-left: 28%;
    // width: 65%;
  }

  &:first-child {
    margin-top: 0;
  }

  &.active { opacity: 1; height: auto; }

  .question-title-wrapper {
    @include font-size($font-size-xxxl-plus);

    @include careers-mobile-only() {
      @include font-size(28px);
    }
  }
  .question-title {
    display: inline-block;
    font-size: inherit;
    margin-bottom: .3em;
    @extend .font-family-secondary;
    font-weight: bold;
    transition: none;

    &:hover {
      color: $color-white;
    }
  }


  .question-options {
    @include font-size($font-size-lg);
    @include careers-mobile-only() {
      @include font-size(22px);
    }

    .question-option-items {
      display: block;
      margin: 0 -.25em;
    }

    .question-option-item {
      position: relative;
      display: inline-block;
      padding: 0 .25em;
      margin:0 .25em .5em;
      margin-bottom: .5em;

      &:before {
        position: absolute;
        top: .2em;
        left: 0;
        z-index: 1;
        content: " ";
        width: .15em;
        height: 80%;
        background-color: transparent;
        @include transition(background-color .25s ease-in);
      }

      &:hover {
        &:before {
          background: $color-brand-light;
        }
      }
    }

    .question-option-item-title {
      padding: .15em .25em;
      color: inherit;
    }

    &.location {
      .question-option-item-title {
        &:after {
          vertical-align: text-top;
          font-size: .85em;
          margin-left: .5em;
          content: "\007c";
        }
      }
    }
  }

  @include max-width-md {
    width: auto;
    margin: 0;
    padding:0 3%;
  }
}

.cursor-type-fx {
  position: relative;

  .cursor-fx-item {
    position: relative;
    font-style: inherit;
    opacity: 0;
    visibility: hidden;
    @include transition(all .07s linear);
  }

  .questionnaire-step.active &,
  &.active {

    &:before {
      @include animation( blinker 1s linear infinite );

      @keyframes blinker {
        50% { opacity: 0; }
      }
    }

    .cursor-fx-item {
      opacity: 1;
      visibility: visible;
    }
  }

  .questionnaire-step.completed &,
  &.complete,
  &.fx-completed.active,
  &.fx-completed {
    &:before {
      @include animation-name(none); //stop blink
    }

    .cursor-fx-item {
      @include transition(none);
    }
  }
}

//stop the transition from messing up 'start over'
.question-unstarted .cursor-fx-item {
  transition-delay: 0s !important;
}

.cursor-style {
  position: relative;
  padding-left: .5em;

  &:before {
    position: absolute;
    top: .2em;
    left: 0;
    z-index: 1;
    content: " ";
    width: .15em;
    height: .8em;
    background: $color-brand-one;
  }
  &.white {
    &:before { background-color: $color-brand-light; }
  }
}

.question {

    &-title {
        color: white;
        font-size: 45px;
        font-weight: bold;
    }

    &-conclusion a {
        color: white;
        font-size: 45px;
        font-weight: bold;
    }
}
