.experience-connection {
    &__border {
        border: thin solid $color-grey-zero;
        border-width: thin 0 0;
        padding-top: 1.5em;
        margin-top: 1.5em;

        & + & {
            border-width: thin 0 0;
        }

        &:last-child {
            border-bottom-width: thin;
            padding-bottom: 1.5em;
            margin-bottom: 1.5em;
        }
    }

    &__border-top {
        border-top: thin solid $color-grey-zero;
        padding-top: 1.5em;
        margin-top: 1.5em;
    }

    &__header {
        margin-bottom: 1em;

        &--addition {
            margin-top: 2em;
        }
    }

    &__back-track {
        padding-top: 2em;
        margin-bottom: 2em;
    }

    &__title {
        font-family: $font-family-univers-condensed-bold;
        text-transform: uppercase;
        line-height: 1.1;
        margin-bottom: 0.2em;
        font-size: px-to-em(18px);
    }

    &__subtitle {
        font-size: px-to-em(18px);
        line-height: 1.1;
    }

    &__closing {
        margin-top: 0.2em;
        font-size: px-to-em(18px);

        &:before {
          content: '— ';
        }
    }
    &__closing-link {
        margin-top: 0.2em;
    }
    
    &__link {
        color: $color-alizarin-dark;
        text-transform: uppercase;

        @include ico-icon-after($icon-chevron-right-thin) {
            padding-left: 0.25em;
            position: absolute;
            margin-top: 0.1em;
        }

        &--back {
            padding-left: 0;
            text-transform: uppercase;
            
            @include ico-icon-before($icon-chevron-right-thin) {
                padding-right: 0;
                padding-left: 0;
                position: relative;
                margin-top: 0;
                transform: rotateY(180deg) translateX(15%);
            }

            &:after {
                display: none;
            }
        }

        &--plus {
            @include ico-icon-after($icon-plus) {
                padding-left: 0.5em;
            }
        }
    }

    &__copy {
        @include font-secondary();
        font-size: px-to-em(18px);
        overflow: hidden;

        underline {
            font-family: inherit;
        }

        em {
            font-family: inherit;
            font-style: italic;
        }

        strong {
            font-family: inherit;
            font-style: bold;
        }

        .rte-blockquote {
            @include font-secondary();
            font-size: 1.2em;
        }
    }

    &__image {
        background-repeat: no-repeat;
        background-size: contain;
        height: 370px; //rene please adjust if needed.
    }

    &__media {
        margin: 1.5em 0;

        &-iframe {
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: 56.25%;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }

        iframe,
        object {
            max-width: 100%;
            display: block;
        }

        video {
            width: 100%;
        }

        & + .experience-connection__closing-link {
            border-top: 0;
            margin-top: 0;
            padding-top: 0;
        }

        &:not(:last-child) {
            border-bottom: 0;
        }

    }

    &__quote {
    
    }

    @include min-width-md {
        &__border {
            padding-top: 2em;
            margin-top: 2em;

            &:last-child {
                border-bottom-width: thin;
                padding-bottom: 2em;
                margin-bottom: 2em;
            }
        }

        &__border-top {
            padding-top: 2em;
            margin-top: 2em;
        }

        &__header {
            margin-bottom: 2em;
        }

        &__closing,
        &__link {
            font-size: px-to-em(20px);
        }

        &__title,
        &__copy {
            font-size: px-to-em(24px);
        }
    }

    .careers-float-left,
    .careers-float-right {
        float: left;
        max-width: 20%;
        margin-right: 16px;
        margin-bottom: 5px;
        height: auto !important;
        display: block;

        > img {
            max-width: 100%;
            display: block;
            height: auto !important;
            display: block;
        }
    }

    .careers-float-left--gray,
    .careers-float-right--gray {
        float: left;
        max-width: 20%;
        margin-right: 16px;
        margin-bottom: 5px;
        height: auto !important;
        display: block;
        overflow: hidden;
        border-radius: 100%;

        > img {
            max-width: 100%;
            display: block;
            height: auto !important;
            display: block;
            filter: grayscale(1);
        }
    }

    .careers-float-right,
    .careers-float-right--gray {
        float: right;
        margin-left: 16px;
        margin-right: 0;
    }
}
