@use "sass:math";

input {
  box-sizing: border-box;
  height: $input-height;
  padding: 0 math.div($spacing, 2);
  border: $form-border;
}

.input-field {
  position: relative;
  display: inline-block;
  z-index: $z-index-main;

  .icon {
    @extend .interactive;
    position: absolute;
    right: 0;
    top: 0;
    z-index: $z-index-main + 2;
    height: $input-height;
    line-height: $input-height;
    margin: 0;
    padding: 0 math.div($spacing, 2);
    vertical-align: middle;
    color: $color-grey-one;
  }
}

.select-field {
  border: $form-border;
  width: 100%;
  overflow: hidden;
  .icon {
    font-size: $font-size-xl;
  }
}

select {
  @extend .interactive;
  height: $input-height;
  width: 100%;
  padding: math.div($spacing, 4) math.div($spacing, 2.5);
  padding-right: ($spacing * 2);
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
}

.search-field {
  position: relative;
  width: 100%;

  .icon {
    font-size: $font-size-xl;
  }

  .search-input {
    width: 100%;
    padding-right: ($spacing * 2);
  }
}
