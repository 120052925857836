.scroll-transition-items {
  position: absolute;
  top: 0;
  left: 5%;

  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-size: 100% auto;
}

.scroll-transition-alt-wrapper {
  position: absolute;
  height: 90vh;
  width: 100%;
  top: 0;
  left: 0;
}

.scroll-transition-items-full {
  position: fixed;
  top: 0;
  left: 0;

  //fallback if calc isn't supported
  width: 60%;
  width: calc(66% - 60px);
  //we want a 4:3 aspect ratio for these desktop images
  height: 0;
  padding-top: 40%;

  // height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: bottom;
  background-color: transparent;
  background-size: 100% auto;
}

.transition-item {
  position: absolute;
  z-index: 0;
  width: 250px;
  height: 250px;
  @include transition(transform 1s ease-out);

  &:hover {
    z-index: 200 !important;
  }

  .before & {
    @include translate(0, 50%);
  }

  &.active {
    @include translate(0, 0);
  }

  > a {
    display: block;
  }

  &.item-1 {
    background-color: green;
    top: 25%;
    right: 6%;
    z-index: 3;

    @include transition-delay(0.5s);

    .before & {
      @include translate(0, 70%);
    }
  }

  &.item-2 {
    background-color: blue;
    @include border-radius(100%);
    position: absolute;
    top: 240px;
    left: 230px;
    z-index: 2;
    @include transition-delay(0.25s);

    .before & {
      @include translate(0, 80%);
    }
  }

  &.item-3 {
    background-color: red;
    top: 15%;
    left: 5%;
    z-index: 1;

    .before & {
      @include translate(0, 50%);
    }
  }
}
