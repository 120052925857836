.pop-content {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  // background-color: $color-brand-pop;
  color: $color-brand-light;
  visibility: visible;

  //override computed inline style only on the way out
  &:not(.active) {
    transition-delay: 0s !important;
  }

  @include transition(height 0.3s linear);

  .title {
    @include font-size($font-size-md);
  }

  .description {
    margin-top: -0.2em;
  }

  > .close-icon {
    // font-weight: bold;
    position: absolute;
    right: $spacing * 1.1;
    top: $spacing * 1.1;
    margin: 0;
    z-index: 2;
    font-size: 24px;
    cursor: pointer;
    color: $color-alizarin;
  }

  .pop-content-content {
    padding-top: $spacing;
  }

  .content-inner {
    padding-right: $spacing;
  }

  &.pop-content-overlist {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
  }
}

.pop-content-faded {
  opacity: 0;
  pointer-events: none;
}

.pop-content-list {
  .item-inner {
    transition: none;
    opacity: 1;
  }
}

//the transition delay for these individual items is calculated in factslist.cshtml
.pop-content-list-item {
  @include transition(
    opacity 0.2s linear,
    padding 0.4s ease-out,
    max-height 0.4s ease-out
  );

  //without height set, the container gets too big for an instant on the transition and screws with the nav bar
  height: 115px;
  //max height for transition
  max-height: 115px;

  &.float-to-top {
    max-height: none;
  }
}

.singled-out .pop-content-list-item:not(.float-to-top) {
  max-height: 0;
  //override inline transition delay after they fade
  transition-delay: 0s !important;
  // transition: padding .3s linear, max-height .3s ease-in;
  padding: 0;
}

.pop-content-rich-text {
  @include transition(opacity 0.3s linear);
  @extend .font-family-secondary;
  font-size: 24px;
  margin-top: 0.5em;

  &.active {
    opacity: 0.8;
  }
}
