.panel-grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  .pseudo-grid {
    width: 100%;
    height: 100%;
  }
  .grid-item {
    padding: 0 $spacing * 4;
    border-color: $color-grey-five;
    border-bottom: 1px solid;
    border-left: 1px solid;
    vertical-align: middle;
    width: 33%;
    z-index: 1;

    @media screen and (max-height: 700px) {
      padding-bottom: $spacing;
    }

    &:first-child {
      border-left: 0;
    }
  }
  .grid-row {
    &:last-child {
      .grid-item {
        border-bottom: 0;
      }
    }
  }

  .grid-item-title {
    @include font-size($font-size-md);
    font-weight: bold;
    text-transform: uppercase;
    color: $color-grey-two-dark;
    @include max-width-sm() {
      // line-height: 1.2;
      @include font-size($font-size-md - 1);
    }
  }
  .grid-item-value {
    @include font-size($font-size-xmega);
    font-weight: bold;
    @extend .font-family-display;
    line-height: 1;
  }

  @include max-width-lg() {
    .grid-item-value {
      @include font-size($font-size-xmega - 8);
    }
  }

  @include max-width-md() {
    .grid-item {
      padding: 3% 4%;
    }
    .grid-item-value {
      @include font-size($font-size-jumbo);
      line-height: 1;
    }
  }

  @include max-width-sm() {
    .grid-item {
      padding: 3% 2%;
    }
    .grid-item-value {
      @include font-size($font-size-jumbo - 6);
    }
  }
}

//only show top left or right fact on mobile in a 4x4 grid
@include careers-mobile-only() {
  .grid-item-inner {
    // visibility: hidden;
    display: none;
  }

  .grid-left {
    .grid-row-first .grid-item:nth-child(3) {
      vertical-align: middle;
      //stay below "meet X"
      // padding-top: 40px;
      .grid-item-inner {
        // visibility: visible;
        display: block;
        text-align: right;
      }
    }
    .grid-item:first-child {
      display: none;
    }
    .grid-item:nth-child(2) {
      border-left: 0;
    }
  }

  .grid-right {
    .grid-row-first .grid-item:first-child {
      vertical-align: middle;
      // padding-top: 40px;
      .grid-item-inner {
        // visibility: visible;
        display: block;
        text-align: left;
      }
    }

    .grid-item:last-child {
      display: none;
    }
    .grid-item:nth-child(2) {
      border-right: 0;
    }
  }
}
