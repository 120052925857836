@use "sass:math";

.button {
  @include display(inline-block);
  @include font-size($font-size-sm);

  padding: 0.8rem;
  border: 0;
  outline: 0;
  line-height: 1;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  user-select: none;
  background-color: $color-button-background;
  color: $color-button-color;

  &:active,
  &:focus,
  &:hover {
    color: darken($color-button-color, 10%);
    background-color: $color-button-background-alt;
  }
}

.button-tall {
  padding: 1.2rem;
}

.button-wide {
  display: block;
  max-width: 100%;
}

.buttons {
  margin: math.div($spacing, 2) 0;
}

.button-reset {
  @include font-size($font-size-lg);
  border: 1px solid $color-brand-one;
  text-transform: none;
}
