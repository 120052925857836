.breadcrumbs {
  height: 100%;
  padding: 0 $spacing;

  // @include max-width-md() {
  //   display: none;
  // }

  .breadcrumbs-list {
    position: relative;
    top: 50%;
    margin: 0;
    padding: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .breadcrumbs-item {
    display: inline-block;
    color: $color-grey-one;
    @include font-size($font-size-md);
    font-weight: bold;

    a {
      color: $color-grey-two;

      &:hover {
        color: $color-grey-one;
      }
    }
  }
}
