@use "sass:math";

.message-items {
  .message-item {
    color: $color-brand-light;
    font-size: $font-size-md;

    a {
      color: inherit;
    }

    .icon {
      font-size: $font-size-md;
    }

    .message-content {
      padding: $spacing 0;
      background-color: $color-message-default;
    }

    &.alert {
      .message-content {
        background-color: $color-message-alert;
      }
    }
  }

  .message-item-columns {
    display: table;

    .col {
      display: table-cell;
      vertical-align: middle;
      padding-right: math.div($spacing, 2);
    }
  }
}
