$color-white: #fff;
$color-white-transparent: rgba(255, 255, 255, 0);
$color-black: #000;
$color-black-transparent: rgba(0, 0, 0, 0);

$color-alizarin: #e82011;
$color-alizarin-dark: #DB4535;
$color-persian-indigo: #3c1053;
$color-dark-cyan: #008c95;
$color-golden-poppy: #f1c400;
$color-teal: #2bb3d5;
$color-grey-zero: #111111;
$color-grey-one: #333;
$color-grey-two: #5f5f5f;
$color-grey-two-dark: #58595b;
$color-grey-three: #626262;
$color-grey-header: #9b9b9b;
$color-grey-four-dark: #939598;
$color-grey-four: #979797;
$color-grey-five: #d2d2d2;
$color-grey-six: #d9d9d9;

$color-brand-light: $color-white;
$color-brand-dark: #1b1b1b;
$color-brand-one: $color-alizarin;
$color-brand-two: $color-grey-one;

$color-link: $color-brand-dark;
$color-link-alt: $color-brand-dark;

$color-button-color: $color-brand-light;
$color-button-background: $color-brand-dark;
$color-button-background-alt: $color-brand-dark;

$color-brand-pop: $color-brand-two;

$color-message-default: $color-brand-one;
$color-message-alert: $color-brand-one;

$color-footer-title: $color-brand-light;
$color-footer-border: $color-brand-light;

$color-slogan-bg: #2e2e2e;

$background-color: $color-brand-light;
$background-color-dark: $color-brand-dark;

$page-title-color: $color-brand-two;
