@use "sass:math";

.panel-content-list {
  position: relative;
  float: right;
  width: 33%;
  margin: ($spacing * 3) ($spacing * 3) ($spacing * 3) 0;

  @include careers-mobile-only {
    float: none;
    width: 94%;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 0;
  }

  .panel-content-list-item {
    border-top: 1px solid $color-grey-three;
    padding: math.div($spacing, 2) 0;

    .item-inner {
      position: relative;
      z-index: 1;

      > .icon {
        font-size: 30px;
        position: absolute;
        right: 0;
        top: 1em;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .pop-content-overlist {
      z-index: 2;

      .description {
        margin-bottom: 0.5em;
      }
    }
  }

  .pretitle {
    display: block;
    @include font-size($font-size-accessible-bold);
    font-weight: bold;
    text-transform: uppercase;

    &:not(.no-margin-bottom) {
      margin-bottom: 5px;
    }
  }

  .description {
    @extend .font-family-secondary;
    @include font-size($font-size-md-plus);
  }

  &.large-description {
    .description {
      @extend .font-family-display;
      @include font-size($font-size-xxxl-plus);

      @media screen and (max-width: $screen-between) {
        @include font-size($font-size-xxxl);
        font-size: 7vw;
      }
    }

    @media screen and (max-width: $screen-between) {
      .pop-content-rich-text {
        font-size: $font-size-md-plus;
      }
    }
    //stop lengthy facts from cutting off on smaller desktop widths
    @include careers-desktop-only {
      min-width: 550px;
    }

    @include max-width-sm() {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }

  &.interactive-list {
    .pretitle {
      color: $color-brand-one;
      margin-bottom: 0;
      font-weight: normal;
      font-family: "UniversLTStd-CondensedBold";
    }
    //overwritting default styles to have better control of animations with reference to carve your path panel.
    // Where the transition delays of the expandable accordion conflicted with transitions from the intro
    .panel-content-list-item {
      // @extend .interactive;
      border-top-color: transparent;
      padding: 0;

      .item-inner {
        border-top: 1px solid $color-brand-light;
        padding: math.div($spacing, 2) 0;
      }

      &:last-child:not(.float-to-top) {
        @include careers-desktop-only() {
          .item-inner {
            border-bottom: 1px solid $color-brand-light;
          }
        }
      }
    }
  }
  //nvm these are always the same color
  &.dark-text .panel-content-list-item:not(.desktop-interaction-list-item) {
    .pretitle {
      color: $color-grey-zero;
    }

    .description {
      color: $color-brand-dark;
    }
  }
}

//the gradient wrapper sets the width and right position here
.panel-content-list-bio {
  @include careers-desktop-only {
    width: 90%;
    //leave room for margin and gradient to start
    width: calc(90% - 60px);
  }

  @media screen and (min-width: $screen-md) and (max-width: $screen-xl) {
    margin-right: 2vw;
    width: calc(90% - 2vw);
  }

  @include careers-mobile-only {
    margin-top: 0;
  }

  .panel-content-list-item:first-child {
    .description {
      font-size: 30px;
      font-weight: bold;
    }
  }
}

.panel-content-list-gradient-wrapper {
  @include careers-desktop-only {
    float: right;
    //fallback
    width: 30%;
    //85 for a bit of overlap from the max-width div
    width: calc(
      100vw - #{math.div(80vh, $desktop-aspect-ratio)} + #{math.div(
          125px,
          $desktop-aspect-ratio
        )}
    );
    //set min width so it stops shrinking when the image stops shrinking
    min-width: 25vw;
  }
}

html:not(.mobile-detected) {
  .panel-content-list.min-parallax-height {
    min-height: calc(100vh - 100px);
  }
}

.no-transform {
  transform: none !important;
}

@include careers-desktop-only {
  //fade the gradient in with the background image
  .gradient-light-text-on-dark,
  .gradient-dark-text-on-light {
    opacity: 0;
    @include transition(opacity 0.3s);
  }

  .gradient-light-text-on-dark {
    background: linear-gradient(
      to right,
      $color-black-transparent,
      $color-black
    );
  }

  .gradient-dark-text-on-light {
    background: linear-gradient(
      to right,
      $color-white-transparent,
      $color-brand-light
    );
  }

  .para-panel.active {
    .gradient-light-text-on-dark,
    .gradient-dark-text-on-light {
      opacity: 1;
    }

    @if ($ENABLE_DIM) {
      @include careers-desktop-only {
        .has-active-interaction {
          .gradient-dark-text-on-light {
            background: none;
          }
        }
      }
    }
  }
}
