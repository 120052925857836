@use "sass:math";

.profile {
    position: relative;
    overflow: hidden;
    z-index: 2;

    &__panel {
        &:focus {
            outline: thin dotted;
        }
    }

    &__mobile-heading {
        position: relative;
        // min-height: 256px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        padding-bottom: 60%;
    }

    &__heading {
        &-title {
            font-family: $font-family-univers-condensed-bold;
            font-size: px-to-em(26px);
            width: 100%;
            margin-bottom: 0;
            text-transform: uppercase;
        }

        &-subtitle {
            font-family: $font-family-univers-condensed-light;
            font-size: px-to-em(26px);
            line-height: 1.1;
            text-transform: uppercase;

            a {
                color: $color-alizarin;

                &:hover {
                    color: $color-black;
                }
            }
        }
    }

    &__content {
        border: 1px dashed $color-grey-zero;
        border-width: thin 0;
        padding: 2em 0;
    }

    &__background {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -3;
        background: $color-white no-repeat center;
        background-size: cover;
        transform: translateZ(0);
    }

    &__photo {
        position: absolute;
        top: 0;
        // left: px-to-em(-30px);
        width: 90%;
        z-index: -2;
        transform: translateZ(0);
    }

    &--right-align & {
        &__photo {
            left: auto;
            right: 0;
        }
    }

    &__connections {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: $spacing;
    }

    &__connection {
        max-width: 140px;
        flex: 1 1 140px;
        margin-right: 9px;
        margin-bottom: 9px;
        position: relative;
        overflow: hidden;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border: 4px solid transparent;
            transition: border-color 0.25s;
        }

        &--is-active {
            &:after {
                border-color: $color-alizarin;
            }
        }

        &-image {
            background: $color-white no-repeat center;
            background-size: cover;
            width: 100%;
            padding-bottom: 100%;
        }
    }

    &__connection-message {
        // @include font-secondary();
        // font-style: italic;
        width: 100%;
        color: $color-alizarin-dark;
        text-transform: uppercase;
        line-height: 1.2;
        margin-bottom: 1em;
    }

    &__body {
        margin: $spacing;
    }

    &__heading {
        padding-top: $spacing * 1.5;
    }

    &__container {
        background-color: $color-white;
        padding: 0.1em 1em 2em;
    }

    @include min-width-md {

        &__connection {
            max-width: 140px;
            flex: 1 1 calc(25% - 9px);
        }

        min-height: calc(100vh - #{px-to-em($site-header-height)});

        &:before {
            content: '';
            display: block;
            // background-color: $color-white;
            height: 100%;
            width: 55vw;
            position: absolute;
            left: calc(50% - 2em);
            top: 0;
            z-index: -1;
        }

        &--right-align {
            &:before {
                left: auto;
                right: calc(50% - 2em);
            }
        }

        &__heading {
            display: block;
            padding-top: $spacing;

            &-title {
                font-size: px-to-em(48px);
            }
        }

        &__mobile-heading {
            position: static;
            padding: 0;
            display: block;
            min-height: auto;
        }

        &__mobile-heading & {
            &__heading-title,
            &__heading-subtitle {
                display: none
            }
        }

        &__connection-message {
            font-size: px-to-em(20px);
        }

        &__container {
            background-color: transparent;
            max-width: 1400px;
            margin-left: auto;
            margin-right: auto;
            min-height: 100%;
            padding: 5vh px-to-em(40px) px-to-em(30px);
        }

        &__panel-frame {
            margin: 0;
        }

        &__content {
            margin-top: px-to-em(30px);
            padding: px-to-em(30px) 0;
        }

        $column-width: 47%;
        $margin-offset: 53%;

        &__panel-frame,
        &__column {
            margin: 0;
            width: $column-width;
            margin-left: $margin-offset;
            position: relative;
        }

        $photo-offset: 0%;

        &__photo-frame {
            position: absolute;
            left: 0;
            width: 50%;
            top: 0;
            z-index: -2;
            height: calc(100vh - #{px-to-em($site-header-height)});
            // height: 100%;
        }

        &--sticky & {
            &__photo-frame {
                position: fixed;
                top: px-to-em($site-header-height);
            }
        }

        &--bottom-position & {
            &__photo-frame {
                position: absolute;
                top: auto;
                bottom: 0;
            }
        }

        &__photo {
            object-fit: contain;
            object-position: bottom left;
            width: 100%;
            height: 100%;
            left: auto;
            top: auto;
            right: 0;
            bottom: 0;
            max-width: none;
        }


        &--right-align & {
            &__mobile-heading {
                padding: 0;
            }

            &__heading {
                padding-right: 0;
                padding-left: 0;
            }

            &__photo {
                object-position: bottom right;
                right: auto;
                left: 0;
            }

            &__photo-frame {
                right: 0;
                left: auto;
            }

            &__panel-frame,
            &__column {
                margin-left: 0%;
            }
        }

        &__connection {
            // width: px-to-em(106px);
            // height: px-to-em(106px);

            &:hover {
                &:after {
                    border-color: $color-black;
                }
            }

            &--is-active {
                &:hover {
                    &:after {
                        border-color: $color-alizarin;
                    }
                }
            }
        }

        &__mobile-only {
            display: none;
        }

        &:not(&__mobile-only) {
            display: block;
        }
    }

    @include min-width-xl {
        &__photo {
            left: auto;
            right: 0;
            width: 100%;
            height: 100%;
            bottom: px-to-em($site-header-height) * -1;
            top: auto;
            // bottom: 0;
        }

        &--right-align & {
            &__photo {
                right: auto;
                left: 0;
            }
        }
    }

    @include ipadpro-portrait {
        &__photo {
            top: auto;
            bottom: 0;
            height: 75%;
            left: 30%;
            transform: translateX(-50%);
        }

        &--right-align & {
            &__photo {
                right: auto;
                left: 80%;
            }
        }
    }
}