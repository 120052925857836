/*==================================
=            DEFINE FONTS             =
==================================*/
$file-path-fonts: "./../fonts/";

$font-family-name: "UniversLTStd";
$font-family-secondary-name: "Lyon Text Web";
$font-family-display-name: "Lyon Display Web";

$font-family: #{$font-family-name}#{", sans-serif"};
$font-family-secondary: #{$font-family-secondary-name}#{", serif"};
$font-family-display: #{$font-family-display-name}#{", serif"};

$primary-font-family: $font-family-name;
$primary-font-filename: "31E031_4_0"; //57
$primary-font-italic-filename: "31E031_0_0";
$primary-font-bold-filename: "31E031_5_0"; //67
$primary-font-bolditalic-filename: "31E031_1_0";
$primary-font-light-filename: "31E031_2_0"; //47
$primary-font-lightitalic-filename: "31E031_3_0";

$secondary-font-family: $font-family-secondary-name;
$secondary-font-filename: "LyonText-Regular-Web";
$secondary-font-italic-filename: "LyonText-RegularItalic-Web";
$secondary-font-bold-filename: "LyonText-Bold-Web";
$secondary-font-bolditalic-filename: "LyonText-BoldItalic-Web";

$display-font-family: $font-family-display-name;
$display-font-filename: "LyonDisplay-Medium-Web";

@mixin font-face-family($font-family, $file-path, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
    src: url("#{$font-file-path-base}#{$file-path}.eot");
    src: url("#{$font-file-path-base}#{$file-path}.eot?#iefix")
        format("embedded-opentype"),
      url("#{$font-file-path-base}#{$file-path}.woff") format("woff"),
      url("#{$font-file-path-base}#{$file-path}.woff2") format("woff2");
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

/*==================================
=            Mixin Helpers             =
==================================*/

@mixin font-face-family($font-family, $file-path, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
    src: url("#{$file-path-fonts}#{$file-path}.eot");
    src: url("#{$file-path-fonts}#{$file-path}.eot?#iefix")
        format("embedded-opentype"),
      url("#{$file-path-fonts}#{$file-path}.woff") format("woff"),
      url("#{$file-path-fonts}#{$file-path}.woff2") format("woff2");
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

@mixin font-primary() {
  font-family: $font-family;
}

@mixin font-secondary() {
  font-family: $font-family-secondary;
}

@mixin font-display() {
  font-family: $font-family-display;
}

/*==================================
=            Load Fonts            =
  based on font style / weight
==================================*/

@include font-face-family(
  $secondary-font-family,
  $secondary-font-filename,
  normal,
  normal
);
@include font-face-family(
  $secondary-font-family,
  $secondary-font-italic-filename,
  normal,
  italic
);
@include font-face-family(
  $secondary-font-family,
  $secondary-font-bold-filename,
  bold,
  normal
);
@include font-face-family(
  $secondary-font-family,
  $secondary-font-bolditalic-filename,
  bold,
  italic
);

@include font-face-family(
  $display-font-family,
  $display-font-filename,
  normal,
  normal
);

/*==================================
=            Style Class Helpers   =
  use as @extend .font-family-primary;
==================================*/
.font-family-primary {
  @include font-primary();
}

.font-family-secondary {
  @include font-secondary();
}

.font-family-display {
  @include font-display();
}
