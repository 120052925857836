.page-title {
  background-color: $page-title-color;
  color: $color-white;
  text-align: center;

  h1 {
    @include font-size($font-size-md);
    margin: 1em 0;
    font-weight: 200;
  }
}
